import '../../JobsList/rolesfilterbar.css';

import { useEffect, useState } from 'react';
import GradesDropdown from './gradesDropdown';
import SubjectsDropdown from './subjectsDropdown';
import SchoolsAPI from 'api/schoolsAPI';

import searchGlassIcon from '../../../assets/icon_search_glass_white.svg';
import { DistrictJobBoardDropDown } from './Dropdowns/DistrictJobBoardFiltersDropdown';
import {
  DistrictJobBoardDropdownOptionsPropsInterface,
  RoleFilterBarProps,
  employmentTypeOptions,
} from './types';
import { ATSDistrictJobsListFiltersDataTestIds } from 'data-testids/ATS';

const RolesSearchBar: React.FC<RoleFilterBarProps> = ({
  district_id,
  query,
  setCurrentQuery,
  grades,
  subjects,
  categories,
  isRequisition = false,
  awaitingRefresh,
  refresh,
  updateMultiSelect,
}) => {
  const externalJobpage = window.location.href.indexOf('/jobs/') !== -1;

  const [searchQuery, setSearchQuery] = useState(query);
  const [schoolsList, setSchoolList] = useState<DistrictJobBoardDropdownOptionsPropsInterface[]>(
    []
  );
  const [fulltime, setFulltime] = useState([]);
  const [filteredSchoolList, setFilteredSchoolList] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    grades: false,
    fulltime: false,
    schools: false,
    subcategoryStateIds: false,
    districtIds: false,
    startDate: false,
  });

  let districtId;

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    districtId = district_id;
    if (district_id != null) {
      SchoolsAPI.fetchActiveSchools(districtId).then(response => {
        const schoolList = response.map(school => ({
          value: school.id,
          label: school.name,
          isChecked: true,
        }));
        setSchoolList(schoolList);
        setFilteredSchoolList(schoolList);
      });
    }
  }, [district_id, districtId]);

  const handleSearch = () => {
    setCurrentQuery({ query: searchQuery });
  };

  const isActiveSetter = fieldName => {
    return value =>
      actions.setActiveFilters(previousState => ({
        ...previousState,
        [fieldName]: value,
      }));
  };

  const filterValues = {
    filteredSchoolList,
    fulltime,
  };

  const actions = {
    setFilteredSchoolList: ids => {
      setFilteredSchoolList(ids);
    },
    setActiveFilters: activeFilters => {
      setActiveFilters(activeFilters);
    },
    setFulltime: fulltime => {
      setFulltime(fulltime);
    },
    setDefaultEmploymentTypes: () => {
      setFulltime(employmentTypeOptions.map(o => o.value));
      setActiveFilters(prev => ({ ...prev, fulltime: false }));
    },
  };

  return (
    <div className="roles-filter-bar">
      <div className="roles-filter-bar-search">
        <div className="search-bar-container-div">
          <input
            className="roles-filter-bar-search-input"
            data-testid={ATSDistrictJobsListFiltersDataTestIds.SEARCH_INPUT}
            type="text"
            placeholder={isRequisition ? 'Search by Title' : 'Search Jobs'}
            value={searchQuery || ''}
            onChange={event => setSearchQuery(event.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
            data-hj-whitelist
          />
        </div>
        {(externalJobpage || isRequisition) && (
          <div
            className={
              isRequisition
                ? 'subjects-grades-filter-container'
                : 'district-jobboard-filters-container'
            }
          >
            <>
              <SubjectsDropdown
                subjects={subjects}
                categories={categories}
                updateMultiSelect={updateMultiSelect}
                isRequisition={isRequisition}
                awaitingRefresh={awaitingRefresh}
                refresh={refresh}
              />
              <GradesDropdown
                grades={grades}
                updateMultiSelect={updateMultiSelect}
                awaitingRefresh={awaitingRefresh}
                refresh={refresh}
              />
            </>
            {isRequisition === false && (
              <>
                <DistrictJobBoardDropDown
                  values={filterValues.filteredSchoolList}
                  setValues={actions.setFilteredSchoolList}
                  options={schoolsList}
                  displayName="Schools / Depts"
                  isActive={activeFilters.schools}
                  setIsActive={isActiveSetter('schools')}
                  updateMultiSelect={updateMultiSelect}
                  showSearchInput={true}
                  showSelectAllButtons
                  error={Error}
                  dataTestId={ATSDistrictJobsListFiltersDataTestIds.SCHOOL_FILTER}
                />
                <DistrictJobBoardDropDown
                  values={filterValues.fulltime}
                  setValues={setFulltime}
                  options={employmentTypeOptions}
                  displayName="Employment Type"
                  isActive={activeFilters.fulltime}
                  setIsActive={isActiveSetter('fulltime')}
                  updateMultiSelect={updateMultiSelect}
                  showSearchInput={false}
                  showSelectAllButtons
                  error={Error}
                  dataTestId={ATSDistrictJobsListFiltersDataTestIds.EMPLOYMENT_TYPE_FILTER}
                />
              </>
            )}
          </div>
        )}

        <button
          className="roles-filter-bar-search-button btn btn-default pull-right"
          onClick={handleSearch}
          style={{ backgroundColor: '#008060' }}
          data-testid={ATSDistrictJobsListFiltersDataTestIds.SEARCH_SUBMIT_BUTTON}
        >
          <img src={searchGlassIcon} alt="search" />
        </button>
      </div>
    </div>
  );
};

export default RolesSearchBar;
