import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { roleSections } from '../../utils/enums';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Stack } from '@mui/material';

export default class RequiredSectionModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    requiredSectionModalSection: PropTypes.arrayOf(PropTypes.object),
    hideRequiredSectionModal: PropTypes.func.isRequired,
  };

  render() {
    const { show, onHide, requiredSectionModalSection } = this.props;

    let specialMessageKey = false;
    const specialMessages = {
      source:
        '"How did you hear about us?" is a required question. Please select an option to continue',
    };

    const sectionsMapped = requiredSectionModalSection.map((s, index) => {
      const comma =
        requiredSectionModalSection.length > 2 && index !== requiredSectionModalSection.length - 1
          ? ', '
          : '';
      const includeAnd =
        (requiredSectionModalSection.length > 2 &&
          index === requiredSectionModalSection.length - 2) ||
        (requiredSectionModalSection.length === 2 && index === 0)
          ? ' and '
          : '';
      let value = roleSections().find(rs => rs.value === s.section)?.label;
      // How did you hear about us is not a formal section, but it is required:

      if ((!value || value === 'none') && s.section === 'source') {
        specialMessageKey = 'source';
        return '';
      }
      return `${value}${comma}${includeAnd}`;
    });

    const message = specialMessageKey
      ? specialMessages[specialMessageKey]
      : 'Please fill in the following required sections before moving forward: ' +
        sectionsMapped.toString();

    return (
      <Modal
        show={show}
        onHide={onHide}
        bsSize="large"
        dialogClassName="work-experience-reminder-modal missing-sections-modal"
      >
        <Modal.Header>
          <div className="modal-title">
            <h3>Friendly Reminder</h3>
          </div>
          <span className="copy-app-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-section">
            <div>{message}.</div>
          </div>
          <Stack direction='row' justifyContent='flex-end'>
              <PrimaryButton
                sx={{ 
                  width: 'auto',
                  height: 50,
                  padding: '0 2rem',
                  margin: '16px',
                  fontSize: '14px',
                }}
                onClick={this.props.hideRequiredSectionModal}
              >
                Edit Sections
              </PrimaryButton>
          </Stack>
        </Modal.Body>
      </Modal>
    );
  }
}
