import { useEffect, useState } from 'react';
import styled from 'styled-components';
import GoogleLogin from './GoogleLogin/GoogleLogin';

import SigninForm from './SigninForm/SigninForm';
import SignupForm from './SignupForm/SignupForm';
import { ATSSignUpDataTestIds } from '../../../data-testids/ATS';

function LoginForm(props) {
  const [authType, setAuthType] = useState('login');

  const handleAuthTypeChange = event => setAuthType(event.target.value);

  const queryParams = useState(window.location.search)[0];
  const [azureError, setAzureError] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const AZURE_ERRORS = {
    'no-user': 'You cannot use an Azure AD account to create a candidate profile',
  };

  useEffect(() => {
    if (queryParams) {
      const params = new URLSearchParams(queryParams);
      const azureError = params.get('azure_error');
      if (Object.keys(AZURE_ERRORS).includes(azureError)) {
        setAzureError(azureError);
      }
    }
  }, [queryParams, AZURE_ERRORS]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const activeElement = document.activeElement;

      // check which element has tab focus
      if (activeElement.classList.contains('google-signin-button')) {
        // currently tab focused on the google signin button
        // fire click event when enter is pressed.
        activeElement.click();
      }

      if (activeElement.classList.contains('azure-signin-button')) {
        // currently tab focused on the azure sign in button
        // fire click event when enter is pressed.
        activeElement.click();
      }
    }
  };

  return (
    <form data-testid={ATSSignUpDataTestIds.SIGN_UP_CONTAINER}>
      <div className="form-radio">
        <label>
          <input
            type="radio"
            value="login"
            checked={authType === 'login'}
            onChange={handleAuthTypeChange}
          />
          Sign in
          <div className="underline-dealie" />
        </label>
        <label data-testid={ATSSignUpDataTestIds.ACTIVATE_BUTTON}>
          <input
            type="radio"
            value="register"
            checked={authType === 'register'}
            onChange={handleAuthTypeChange}
          />
          Sign up
          <div className="underline-dealie" />
        </label>
      </div>

      <div className="login-form-bottom-section" onKeyDown={handleKeyDown}>
        <div
          className="google-login-container"
          data-testid={ATSSignUpDataTestIds.GOOGLE_SIGN_UP_BUTTON}
        >
          <GoogleLogin googleSignin={props.googleSignin} />
        </div>

        <div className="or-divider">OR</div>

        {authType === 'register' ? (
          <SignupForm onRegister={props.onRegister} />
        ) : (
          <SigninForm onLogin={props.onLogin} />
        )}
        <TOSContainer>
          I have read, understand, and agree to be bound by the Nimble{' '}
          <TOSLink
            href="https://www.hirenimble.com/terms"
            rel="noopener noreferrer"
            target="_blank"
          >
            {' '}
            terms of use
          </TOSLink>
          .
        </TOSContainer>
      </div>
    </form>
  );
}

const AzureButton = styled.button`
  width: 100%;

  & > img {
    position: relative;
    left: 8px;
  }
`;

export const StyledErrorText = styled.p`
  margin-left: 8px;
  font-size: 12px;
  height: 1.5em;
  font-weight: normal;
  color: #cc0000;
`;

const TOSContainer = styled.div`
  font-size: 12px;
  color: #828282;
`;

const TOSLink = styled.a`
  font-size: 12px;
  color: #001bf5;
  text-decoration: underline;
  &: link,
  &: visited,
  &: hover,
  &: active {
    color: #001bf5;
    text-decoration: underline;
  }
`;

export default LoginForm;
