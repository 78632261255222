import { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';

import AttachmentInputs from '../components/Application/AttachmentInputs';
import ApplicationProgressBar from '../components/Application/ApplicationProgressBar';
import ErrorText from '../components/errortext';
import Subfooter from '../components/subfooter_poweredby';
import LoadingSpinner from '../components/loadingSpinner';
import auth from '../utils/auth.js';
import { showTotalFailure, showErrorNotification } from '../utils/message';
import { CandidateErrors } from 'sharedComponents/Error/constants';
import { questionType } from '../utils/enums';
import { checkInternal, isValidUrl } from '../utils/util';
import { combineQuestionsAndAttachmentsAndSets } from '../utils/roleutils';
import { Logo } from 'ui-kit';

import ApplicationsAPI from 'api/applicationsAPI';
import RolesAPI from 'api/rolesAPI';
import SchoolsAPI from 'api/schoolsAPI';
import UsersAPI from 'api/usersAPI';

const isLinkValid = (value, isRequired) => {
  if (!value) {
    if (isRequired) {
      return 'URL is required.';
    }
  } else if (!/^https?:\/\//.test(value)) {
    return 'Please enter a valid URL starting with http:// or https://';
  } else if (!isValidUrl(value)) {
    return 'Please enter a valid URL.';
  }
};

class ApplicationDocumentationContainer extends Component {
  constructor(props) {
    super(props);
    this.getUser = auth.getUser();
    let editApplicationMode = window.location.search.indexOf('ea') !== -1 ? true : false;
    this.state = {
      showSpinner: false,
      editApplicationMode,
      job_id: this.props.match.params.id,
      user: {
        profile: {},
      },
      application: {
        explanation: '',
        source: null,
      },
      role: {
        requiredapplicationattachment_set: [],
        custom_questions: [],
        questions: [],
        school_preferences_question: null,
      },
      uploadErrorMessages: {},
      uploads: {},
      // We were previously sending all uploads on each save/autosave,
      // even if they weren't edited. Keep track of which ones have been
      // changed so we only send those ones.
      uploadsToSubmit: new Set(),
      videoLinkAnswersByQuestionId: {},
      backToProfileText: 'Previous step',
      applicationExists: false,
      custom_answers: {},
      showFullPageSpinner: false,
      requiredQuestionsErrors: [],
      jobQuestionsAndAttachmentsAndSets: [],
      // Default this to false; will be set when /role/ call completes
      // This is set in checkInternal (v hacky)
      isInternalCandidate: false,
      // This will determine whether or not to make an event on the backend
      user_made_edits: false,
      activeDistrictSchools: [],
      schoolPreferencesAnswer: {
        is_interested_in_all: false,
        selected_schools: [],
        is_open_to_other_options: false,
        error: '',
      },
    };
    this.checkInternal = checkInternal.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('applicant-pages');
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.setState({ showFullPageSpinner: true });

    this.loadInitialData();
  }

  loadInitialData = async () => {
    let jobQuestionsAndAttachmentsAndSets = [];
    let nimbleAnswers = [];

    try {
      const roleId = this.props.match.params.id;
      const fetchedRole = await RolesAPI.fetchRole(roleId);
      if (!this.ignoreLastFetch) {
        const custom_answers = {};

        let applicationQuestions = [...fetchedRole.custom_questions];

        fetchedRole.question_sets.forEach(q =>
          q.custom_questions.forEach(i => {
            applicationQuestions.push(i);
          })
        );
        fetchedRole.role_question_sets.forEach(q =>
          q.custom_questions.forEach(i => {
            applicationQuestions.push(i);
          })
        );

        applicationQuestions.forEach(cq => {
          let value;

          if (cq.question_type === questionType.multiple_choice) {
            // multiple choice questions start with none selected.
            // answers will come in the form of an array.
            value = [];
          } else if (cq.question_type === questionType.open_response) {
            value = '';
          } else {
            // for yes/no and statement_checkbox questions, add "no" as the default.
            value = false;
          }

          custom_answers[cq.id] = {
            type: cq.question_type,
            value: value,
          };
        });

        jobQuestionsAndAttachmentsAndSets = combineQuestionsAndAttachmentsAndSets(fetchedRole);
        jobQuestionsAndAttachmentsAndSets.forEach(question => {
          // On the candidate application, the question object also stores the answer when
          // the candidate enters their response. if the object is a NimbleQuestion (not CustomQuestion or
          // Attachment), add an 'answer' property.
          if (question.question_type === questionType.nimble) {
            question.answer = '';
          }
        });

        this.checkInternal(this.getUser, fetchedRole.district.id);

        const [fetchedUser, fetchedApplication, activeDistrictSchools] = await Promise.all([
          this.fetchUser(),
          this.fetchApplication(fetchedRole.id),
          SchoolsAPI.fetchActiveSchools(fetchedRole.district.id),
        ]);

        const userAnswersToNimbleQuestions = fetchedUser.answers ?? [];

        this.updateExistingOpenResponseAnswers(
          jobQuestionsAndAttachmentsAndSets,
          userAnswersToNimbleQuestions
        );

        const processedApplicationData = this.processExistingApplicationData(
          fetchedApplication,
          custom_answers,
          fetchedRole
        );

        const uploads = {};
        fetchedApplication.applicationattachment_set.forEach(attachment => {
          uploads[attachment.required_application_attachment] = {
            required_application_attachment: attachment.required_application_attachment,
            attachment: attachment.attachment,
          };
        });

        this.setState({
          role: fetchedRole,
          custom_answers,
          activeDistrictSchools,
          user: fetchedUser,
          applicationExists: true,
          application: fetchedApplication,
          jobQuestionsAndAttachmentsAndSets,
          showFullPageSpinner: false,
          uploads,
          ...processedApplicationData,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      showTotalFailure(error);
    }
  };

  fetchUser = async () => {
    try {
      const currentUser = this.getUser;
      const fetchedUser = await UsersAPI.fetchUser(currentUser.id);
      return fetchedUser;
    } catch (error) {
      Sentry.captureException(error);
      showErrorNotification();
    }
  };

  fetchApplication = async roleId => {
    try {
      const fetchedApplication = await ApplicationsAPI.getCandidateApplicationByRoleId(roleId);
      if (!fetchedApplication) {
        throw new Error(`No application found for Candidate ${this.getUser.id} and Role ${roleId}`);
      }

      if (fetchedApplication.source && fetchedApplication.source.id) {
        fetchedApplication.source = fetchedApplication.source.id;
      }
      return fetchedApplication;
    } catch (error) {
      Sentry.captureException(error);
      showErrorNotification();
    }
  };

  processExistingApplicationData = (application, customAnswers, role) => {
    const videoLinkAnswersByQuestionId = {};

    application.applicationattachmentvideourl_set.forEach(videoLinkAnswer => {
      const videoLinkQuestionId = videoLinkAnswer.required_application_attachment_id;
      videoLinkAnswersByQuestionId[videoLinkQuestionId] = {
        id: videoLinkAnswer.id,
        value: videoLinkAnswer.video_url,
        error: isLinkValid(videoLinkAnswer.video_url),
      };
    });

    let customAnswersLength = application.custom_answers.length;
    while (customAnswersLength--) {
      let key = Number(application.custom_answers[customAnswersLength].question);
      let value;
      let type;

      if (application.custom_answers[customAnswersLength].text) {
        value = application.custom_answers[customAnswersLength].text;
        type = questionType.open_response;
      } else if (application.custom_answers[customAnswersLength].yes !== null) {
        value = application.custom_answers[customAnswersLength].yes;
        type = questionType.yes_no;
      } else if (application.custom_answers[customAnswersLength].agreed_to_statement !== null) {
        value = application.custom_answers[customAnswersLength].agreed_to_statement;
        type = questionType.statementCheckbox;
      } else if (
        application.custom_answers[customAnswersLength].mc_answer &&
        application.custom_answers[customAnswersLength].mc_answer.length > 0
      ) {
        value = application.custom_answers[customAnswersLength].mc_answer;
        type = questionType.multiple_choice;
      }
      if (value !== undefined && customAnswers.hasOwnProperty(key)) {
        customAnswers[key] = { type, value };
      }
    }

    const schoolPreferencesAnswer = {};
    const answer = application.school_preferences_answer;
    if (answer) {
      schoolPreferencesAnswer.is_interested_in_all = answer.is_interested_in_all;
      if (answer.is_interested_in_all) {
        /**
         * When is_interested_in_all is selected, all available choices should be selected,
         * and so should is_open_to_other_options. (spec)
         */
        schoolPreferencesAnswer.selected_schools = [
          ...new Set([
            // (spec) we want to persist old choices even if they're no longer available.
            ...answer.selected_schools,
            ...this.getSchoolPreferencesQuestionChoices(role).map(school => school.id),
          ]),
        ];
        schoolPreferencesAnswer.is_open_to_other_options = true;
      } else {
        schoolPreferencesAnswer.selected_schools = answer.selected_schools;
        schoolPreferencesAnswer.is_open_to_other_options = answer.is_open_to_other_options;
      }
    }

    const updatedVideoLinkAnswersByQuestionId = {
      ...this.state.videoLinkAnswersByQuestionId,
      ...videoLinkAnswersByQuestionId,
    };
    const updatedSchoolPreferencesAnswer = {
      ...this.state.schoolPreferencesAnswer,
      ...schoolPreferencesAnswer,
    };

    const processedApplicationData = {
      custom_answers: customAnswers,
      videoLinkAnswersByQuestionId: updatedVideoLinkAnswersByQuestionId,
      schoolPreferencesAnswer: updatedSchoolPreferencesAnswer,
    };

    return processedApplicationData;
  };

  updateExistingOpenResponseAnswers = (jobQuestionsAndAttachmentsAndSets, answers) => {
    jobQuestionsAndAttachmentsAndSets.forEach(q => {
      answers.forEach(a => {
        if (q.nimblequestion_id === a.question.id) {
          q.answer = a.answer;
        }
      });
    });
  };

  isRequired = item => {
    if (this.state.role.internal_requirements_specified) {
      return (
        (!this.state.isInternalCandidate && item.is_required_external) ||
        (this.state.isInternalCandidate && item.is_required_internal)
      );
    } else {
      return item.is_required_external;
    }
  };

  // MAIN FUNCTION THAT RUNS ON PAGE SUBMITS
  handleSubmit = async (e, autosave) => {
    /*
     ** Autosaving will never proceed user to next step so initialize based on that.
     ** We'll flip `proceedToNextStep` along the way if there are errors in final submission
     */
    let proceedToNextStep = autosave ? false : true;
    if (!autosave) {
      this.startSpinner();
      // Reset the question errors if trying to submit and move on so errors will be up-to-date
      this.setState({ requiredQuestionsErrors: [] });
      e.preventDefault();
    }
    // Update the user
    const requiredQuestionsErrors = [];
    try {
      // Update user data with open response answers
      const answers = [];
      this.state.jobQuestionsAndAttachmentsAndSets.forEach(item => {
        // only push NimbleQuestions (not CustomQuestion or Attachment)
        if (
          item.question_type === questionType.nimble ||
          item.question_type === questionType.text_question_model
        ) {
          // If the question is required and not filled out, mark that ID to show an error
          if (this.isRequired(item) && !item.answer) {
            requiredQuestionsErrors.push(item.id);
          }
          answers.push({
            answer: item.answer,
            question: {
              id: item.nimblequestion_id,
            },
          });
        }
      });

      // Only patch the answers; other user data is unaffected on this page
      await axios.patch(`/api/user/${this.state.user.id}/`, { answers });
    } catch (error) {
      let errorMessage;
      let response = error.response;
      if (response && response.data && response.data.questions) {
        errorMessage = CandidateErrors.APPLICATION_OPEN_RESPONSE;
      } else {
        errorMessage = CandidateErrors.GENERAL;
      }
      this.stopSpinner();
      showErrorNotification(errorMessage);
      return;
    }

    // Check for attachment errors
    let uploadErrors = {};
    const attachments = this.state.jobQuestionsAndAttachmentsAndSets.filter(
      attachment => attachment.question_type === questionType.attachment
    );
    attachments
      .filter(attachment => this.isRequired(attachment))
      .forEach(attachment => {
        let key = attachment.id;
        let attachmentAlreadyUploaded = false;
        // If they already submitted, look for the attachment
        if (this.state.applicationExists) {
          this.state.application.applicationattachment_set.forEach(entry => {
            if (entry.required_application_attachment?.id === key) {
              attachmentAlreadyUploaded = true;
            }
          });
        }
        if (!attachmentAlreadyUploaded && !this.state.uploads.hasOwnProperty(key)) {
          uploadErrors[key] = 'Required attachment';
        }
      });

    // Build the application from react state, then apply for the role
    const application = { ...this.state.application };

    application.custom_answers = [];
    // Custom_answers is a map between question_id (key) and a
    // value (boolean, null, array, or string)
    Object.entries(this.state.custom_answers).forEach(([key, obj]) => {
      if (obj.type === questionType.yes_no) {
        application.custom_answers.push({
          question: Number(key),
          yes: obj.value,
          mc_answer: null,
        });
      } else if (obj.type === questionType.statementCheckbox) {
        application.custom_answers.push({
          question: Number(key),
          agreed_to_statement: obj.value,
        });
      } else if (obj.type === questionType.multiple_choice) {
        // this is a multiple choice question, so add answer to multiple choice field.
        application.custom_answers.push({
          question: Number(key),
          yes: null,
          mc_answer: obj.value,
        });
      } else {
        // this is an open response question, so add answer to text field.
        application.custom_answers.push({
          question: Number(key),
          text: obj.value,
          yes: null,
          mc_answer: null,
        });
      }
    });

    // Add Video Link Answers
    application.applicationattachmentvideourl_set = Object.entries(
      this.state.videoLinkAnswersByQuestionId
    )
      .filter(([questionId, answer]) => answer.value)
      .map(([questionId, answer]) => ({
        id: answer.id || undefined,
        video_url: answer.value,
        required_application_attachment_id: questionId,
      }));

    // Add SchoolPreferenceQuestion answer
    if (this.getSchoolPreferencesQuestion(this.state.role)) {
      application.school_preferences_answer = {
        ...this.state.schoolPreferencesAnswer,
      };
    }

    /** add errors for custom questions if applicable */
    this.state.jobQuestionsAndAttachmentsAndSets.forEach(cq => {
      if (this.isRequired(cq)) {
        let answer = this.state.custom_answers[cq.id]?.value;
        if (
          (cq.question_type === questionType.open_response && !answer) ||
          (cq.question_type === questionType.multiple_choice && answer.length === 0) ||
          (cq.question_type === questionType.statementCheckbox && !answer)
        ) {
          requiredQuestionsErrors.push(cq.id);
        }
      }
    });

    /** add errors for video link if applicable */
    this.state.jobQuestionsAndAttachmentsAndSets
      .filter(question => question.question_type === questionType.videoLink)
      .filter(question => this.isRequired(question))
      .forEach(videoLinkQuestion => {
        this.validateVideoLinkAnswer(videoLinkQuestion);

        const answer = this.state.videoLinkAnswersByQuestionId[videoLinkQuestion.id];
        if (isLinkValid(answer && answer.value, this.isRequired(videoLinkQuestion))) {
          requiredQuestionsErrors.push(videoLinkQuestion.id);
        }
      });

    /*
    If user has already created an application, they will send a PUT to update it
    Otherwise, send a post to create new application db entry
    */
    let sentApp;
    if (this.state.applicationExists) {
      // Determines proper event creation on the backend
      if (!autosave && this.state.user_made_edits) {
        application['user_edited_application_role_id'] = Number(this.state.job_id);
      }
      try {
        sentApp = await axios.put(
          `/api/applications/${this.state.application.id}/update_application/`,
          application
        );
        sentApp.data = this.state.application;
      } catch (error) {
        Sentry.captureException(error);
        this.stopSpinner();
        showErrorNotification(CandidateErrors.APPLICATION_SUBMISSION);
        return;
      }
    } else {
      try {
        // using var to keep sentApp in lexical scope for the next try block
        sentApp = await axios.post(`/api/role/${this.state.job_id}/apply_for_role/`, application);
      } catch (error) {
        /**
         * NOTE: once this endpoint is successfully hit, the application cannot be resent. Trying to do so will result in the
         * error message below.
         */
        let errorMessage;
        if (
          error.response.data.role &&
          error.response.data.role[0] === 'Application already exists for this role'
        ) {
          errorMessage = CandidateErrors.APPLICATION_ALREADY_EXISTS;
        } else {
          errorMessage = CandidateErrors.APPLICATION_SUBMISSION;
        }
        this.stopSpinner();
        showErrorNotification(errorMessage);
        return;
      }
    }

    // Upload additional attachments
    const { uploads, uploadsToSubmit } = this.state;
    try {
      uploadsToSubmit.forEach(async id => {
        const formData = new FormData();
        formData.append('attachment', uploads[id].attachment, uploads[id].attachment.name);
        formData.append(
          'required_application_attachment',
          uploads[id].required_application_attachment
        );
        formData.append('application_id', this.state.application.id);

        await axios.post(`/api/applications/${sentApp.data.id}/upload_attachment/`, formData);
      });
    } catch (error) {
      console.error(error);
      showErrorNotification(CandidateErrors.APPLICATION_ATTACHMENT_SAVE_ERROR);
      this.stopSpinner();
      return;
    }

    uploadsToSubmit.clear();
    this.setState({ uploadsToSubmit });

    /*
     ** If there are missing required uploads and they're attempting to move on,
     ** display messages here and prevent user from moving to next page
     */
    if (!autosave) {
      // User errors
      if (requiredQuestionsErrors.length > 0) {
        proceedToNextStep = false;
        this.stopSpinner();
        this.setState({ requiredQuestionsErrors });
      }
      if (this.validateSchoolPreferencesAnswer()) {
        proceedToNextStep = false;
        this.stopSpinner();
      }
      // Attachment errors
      if (Object.keys(uploadErrors).length > 0) {
        proceedToNextStep = false;
        this.stopSpinner();
        this.setState({ uploadErrorMessages: uploadErrors });
      } else {
        this.setState({ uploadErrorMessages: {} });
      }
    }

    if (!autosave && proceedToNextStep) {
      let queryString = this.state.editApplicationMode ? `&ea=t` : '';
      this.props.history.push(
        `/applicationconfirmation/?job_id=${this.state.job_id}${queryString}`
      );
    }
  };

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  /* When autosaving, delay ten seconds before sending another POST */
  debouncedHandleSubmit = _.debounce(() => {
    this.handleSubmit(null, true);
  }, 10000);

  uploadAttachment = (event, id) => {
    const fileData = event.target.files[0];
    const uploads = { ...this.state.uploads };
    const uploadErrorMessages = { ...this.state.uploadErrorMessages };

    /* ensure no empty files are uploaded */
    if (typeof fileData === 'undefined' || fileData.size === 0) {
      // If it already has an existing file the user probably just canceled out of the upload
      // dialogue so we're not going to yell at her
      if (uploads[id] && uploads[id].hasOwnProperty('attachment')) {
        return;
      }
      uploadErrorMessages[id] = 'Upload is empty, please try again';
      this.setState({ uploadErrorMessages });
      return;
    }

    /* no reasonable file upload should be greater than 5MB in size */
    if (fileData.size > 5242880) {
      uploadErrorMessages[id] = 'Attachments must be smaller than 5MB';
      this.setState({ uploadErrorMessages });
      return;
    }

    /* only proper file types can be uploaded */
    if (
      !['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(
        fileData.name
          .split('.')
          .pop()
          .toLowerCase()
      )
    ) {
      uploadErrorMessages[id] =
        'Please ensure your attachment file name ends in one of the following: .pdf, .doc, .docx, .jpg, .png';
      this.setState({ uploadErrorMessages });
      return;
    }

    /**
     * ~ 30 characters are prepended to file name (/attachments/None/(timestamp)), and max_length
     * is 255, so ensure input file name is no more than 215 characters, to be safe.
     */

    if (fileData.name.length > 215) {
      uploadErrorMessages[id] =
        'Please ensure all attachment file names are fewer than 215 characters';
      this.setState({ uploadErrorMessages });
      return;
    }

    /* if this point is reached, attachment is valid, so remove error messages if they exist */
    if (uploadErrorMessages[id]) {
      delete uploadErrorMessages[id];
    }
    uploads[id] = {
      required_application_attachment: id,
      attachment: event.target.files[0],
    };

    const uploadsToSubmit = new Set(this.state.uploadsToSubmit);
    uploadsToSubmit.add(id);

    this.setState(
      {
        uploads,
        uploadErrorMessages,
        uploadsToSubmit,
        user_made_edits: true,
      },
      this.debouncedHandleSubmit
    );
  };

  clearUploadAttachment = id => {
    let newUploadsCopy = { ...this.state.uploads };
    delete newUploadsCopy[id];
    this.setState({ uploads: newUploadsCopy }, this.debouncedHandleSubmit);
  };

  addVideoLinkAnswer = (videoLinkQuestion, value) => {
    const videoLinkQuestionId = videoLinkQuestion.id;

    this.setState(
      prevState => ({
        videoLinkAnswersByQuestionId: {
          ...prevState.videoLinkAnswersByQuestionId,
          [videoLinkQuestionId]: {
            ...prevState.videoLinkAnswersByQuestionId[videoLinkQuestionId],
            value,
            error: '',
          },
        },
        user_made_edits: true,
      }),
      this.debouncedHandleSubmit
    );
  };

  validateVideoLinkAnswer = videoLinkQuestion => {
    const videoLinkQuestionId = videoLinkQuestion.id;
    const answer = this.state.videoLinkAnswersByQuestionId[videoLinkQuestionId];
    const error = isLinkValid(answer && answer.value, this.isRequired(videoLinkQuestion));

    if (error) {
      this.setState(prevState => ({
        videoLinkAnswersByQuestionId: {
          ...prevState.videoLinkAnswersByQuestionId,
          [videoLinkQuestionId]: {
            ...prevState.videoLinkAnswersByQuestionId[videoLinkQuestionId],
            error,
          },
        },
      }));
    }
  };

  validateSchoolPreferencesAnswer = () => {
    const currentRole = this.state.role;
    const question = this.getSchoolPreferencesQuestion(currentRole);
    const answer = this.state.schoolPreferencesAnswer;
    let error;

    if (!question) return;

    if (this.isRequired(question)) {
      if (
        this.getSchoolPreferencesQuestionChoices(currentRole).length > 0 &&
        answer.selected_schools.length === 0 &&
        !answer.is_interested_in_all &&
        !answer.is_open_to_other_options
      ) {
        error = 'Please select your preferred schools.';
      }
    }

    if (error) {
      this.setState(prevState => ({
        schoolPreferencesAnswer: {
          ...prevState.schoolPreferencesAnswer,
          error,
        },
      }));
    }

    return error;
  };

  // ------ Begin handle change functions ------ //
  // TODO: something like this could be generified to dynamically modify a state dict.
  handleCustomAnswerChange = (id, type, e, isMultiSelect) => {
    const custom_answers = { ...this.state.custom_answers };

    if (type === questionType.multiple_choice) {
      if (custom_answers[id].value.includes(e.target.value)) {
        // answer is already selected, so remove it
        let index = custom_answers[id].value.findIndex(a => a === e.target.value);
        custom_answers[id].value.splice(index, 1);
      } else {
        // Answer is not already selected. We have to determine whether to remove other options.
        // if it's a multi-select, no need to deselect other options. If it's not a multi-select,
        // then we do have to deselect the rest.
        if (!isMultiSelect) {
          // setting length to 0 empties array.
          custom_answers[id].value.length = 0;
        }
        // either way, add in new answer at end.
        custom_answers[id].value.push(e.target.value);
      }
    } else if (type === questionType.open_response) {
      custom_answers[id].value = e.target.value;
    } else if (type === questionType.statementCheckbox) {
      custom_answers[id].value = e.target.checked;
    } else if (type === questionType.yes_no) {
      custom_answers[id].value = e.target.value === 'true';
    }
    this.setState({ custom_answers, user_made_edits: true }, this.debouncedHandleSubmit);
  };

  handleChange = event => {
    let new_value = null;
    if (event.target.type === 'radio') {
      if (typeof event.target.value === 'string') {
        if (event.target.value === 'false') {
          new_value = false;
        } else if (event.target.value === 'true') {
          new_value = true;
        }
      }
    } else {
      new_value = event.target.value;
    }
    this.setState(
      { [event.target.name]: new_value, user_made_edits: true },
      this.debouncedHandleSubmit
    );
  };

  handleApplicationChange = event => {
    let newApplicationCopy = { ...this.state.application };
    newApplicationCopy[event.target.name] = event.target.value;
    this.setState(
      { application: newApplicationCopy, user_made_edits: true },
      this.debouncedHandleSubmit
    );
  };

  handleUserChange = event => {
    let newUserCopy = { ...this.state.user };
    newUserCopy[event.target.name] = event.target.value;
    this.setState({ user: newUserCopy, user_made_edits: true }, this.debouncedHandleSubmit);
  };

  handleProfileChange = event => {
    let new_value = event.target.value;
    if (event.target.type === 'radio') {
      if (typeof event.target.value === 'string') {
        if (event.target.value === 'false') {
          new_value = false;
        } else if (event.target.value === 'true') {
          new_value = true;
        }
      }
    } else if (event.target.type === 'checkbox') {
      new_value = event.target.checked;
    }
    let newUserCopy = { ...this.state.user };
    newUserCopy['profile'][event.target.name] = new_value;
    this.setState({ user: newUserCopy, user_made_edits: true }, this.debouncedHandleSubmit);
  };

  handleQuestionChange = (index, event) => {
    const target = event.target;
    const value = target.value;
    let { jobQuestionsAndAttachmentsAndSets } = this.state;
    jobQuestionsAndAttachmentsAndSets[index].answer = value;
    this.setState(
      { jobQuestionsAndAttachmentsAndSets, user_made_edits: true },
      this.debouncedHandleSubmit
    );
  };
  // ----- End handle change functions ------- //

  getSchoolPreferencesQuestion(role) {
    const question =
      role.school_preferences_question || this.getSchoolPreferencesQuestionFromQuestionSet(role);
    return question;
  }

  getSchoolPreferencesQuestionFromQuestionSet = role => {
    if (!role) {
      return null;
    }

    const roleQuestionSets = role.role_question_sets;
    if (roleQuestionSets && roleQuestionSets.length > 0) {
      const roleQuestion = roleQuestionSets.find(
        roleQuestionSet => roleQuestionSet.school_preferences_question.length > 0
      );
      if (roleQuestion && roleQuestion.school_preferences_question.length > 0) {
        return roleQuestion.school_preferences_question[0];
      }
    }

    const questionSets = role.question_sets;
    if (questionSets && questionSets.length > 0) {
      const question = questionSets.find(
        questionSet => questionSet.school_preferences_question.length > 0
      );
      if (question && question.school_preferences_question.length > 0) {
        return question.school_preferences_question[0];
      }
    }

    return null;
  };

  getSchoolPreferencesQuestionChoices = role => {
    const question = this.getSchoolPreferencesQuestion(role);
    if (!question) {
      return [];
    }

    let schoolChoices = [];
    if (question.is_automatic_list) {
      const schoolIdsWithUnfilledVacancies = role.schoolroles
        .filter(schoolrole => schoolrole.has_unfilled_vacancies)
        .map(schoolRole => schoolRole.school.id);

      schoolChoices = this.state.activeDistrictSchools.filter(school =>
        schoolIdsWithUnfilledVacancies.includes(school.id)
      );
    } else {
      schoolChoices = this.state.activeDistrictSchools.filter(school =>
        question.school_choices.includes(school.id)
      );
    }

    return schoolChoices;
  };

  handleInterestedInAllOpportunitiesToggle = () => {
    this.setState(prevState => {
      const isInterestedInAll = !prevState.schoolPreferencesAnswer.is_interested_in_all;
      const currentRole = prevState.role;

      let selectedSchoolIds, isOpenToOtherOptions;
      if (isInterestedInAll) {
        /**
         * When is_interested_in_all is selected, all available choices should be selected,
         * and so should is_open_to_other_options. (spec)
         */
        selectedSchoolIds = this.getSchoolPreferencesQuestionChoices(currentRole).map(
          school => school.id
        );
        isOpenToOtherOptions = true;
      } else {
        /**
         * When is_interested_in_all is unchecked, all schools and
         * is_open_to_other_options should be unchecked. (spec)
         */
        selectedSchoolIds = [];
        isOpenToOtherOptions = false;
      }

      return {
        user_made_edits: true,
        schoolPreferencesAnswer: {
          ...prevState.schoolPreferencesAnswer,
          is_interested_in_all: isInterestedInAll,
          selected_schools: selectedSchoolIds,
          is_open_to_other_options: isOpenToOtherOptions,
          error: '',
        },
      };
    });
  };

  handlePreferredSchoolToggle = schoolId => {
    let selectedSchools = [...this.state.schoolPreferencesAnswer.selected_schools];
    if (selectedSchools.includes(schoolId)) {
      selectedSchools = selectedSchools.filter(id => id !== schoolId);
    } else {
      selectedSchools.push(schoolId);
    }

    this.setState(prevState => {
      // Remove interested in all flag when a school is unchecked
      const isInterestedInAll = false;

      let selectedSchools = [...prevState.schoolPreferencesAnswer.selected_schools];
      if (selectedSchools.includes(schoolId)) {
        selectedSchools = selectedSchools.filter(id => id !== schoolId);
      } else {
        selectedSchools.push(schoolId);
      }

      return {
        user_made_edits: true,
        schoolPreferencesAnswer: {
          ...prevState.schoolPreferencesAnswer,
          selected_schools: selectedSchools,
          is_interested_in_all: isInterestedInAll,
          error: '',
        },
      };
    });
  };

  handleOpenToOtherLocationsChange = () => {
    this.setState(prevState => {
      const isOpenToOtherOptions = !prevState.schoolPreferencesAnswer.is_open_to_other_options;
      // Remove interested in all flag when a school is unchecked
      const isInterestedInAll = false;

      return {
        user_made_edits: true,
        schoolPreferencesAnswer: {
          ...prevState.schoolPreferencesAnswer,
          is_open_to_other_options: isOpenToOtherOptions,
          is_interested_in_all: isInterestedInAll,
          error: '',
        },
      };
    });
  };

  render() {
    const currentRole = this.state.role;
    if (this.state.showFullPageSpinner || !currentRole.id) {
      return <LoadingSpinner />;
    }
    return (
      <div className="application-step-two-container">
        <div className="header-section">
          <div className="logo-container-outer">
            {currentRole.jobboards[0]?.logo && (
              <Logo src={currentRole.jobboards[0].logo} maxHeight={54} />
            )}
          </div>
          <h2 className="darker-text">Complete your application</h2>
          <div className="header-section-long-text">
            Complete the section below to add supplementary information specific to this job
            application. All fields marked with an asterisk are required.
          </div>
          <ApplicationProgressBar step={3} />
        </div>
        <div className="flex application-container">
          <form className="flex-3" encType="multipart/form-data" onSubmit={this.handleSubmit}>
            <div className="application-section attachments-section">
              <AttachmentInputs
                errorMessages={this.state.uploadErrorMessages}
                role={currentRole}
                uploads={this.state.uploads}
                uploadAttachment={this.uploadAttachment}
                clearUploadAttachment={this.clearUploadAttachment}
                attachments={this.state.application.applicationattachment_set}
                jobQuestionsAndAttachmentsAndSets={this.state.jobQuestionsAndAttachmentsAndSets}
                videoLinkAnswersByQuestionId={this.state.videoLinkAnswersByQuestionId}
                addVideoLinkAnswer={this.addVideoLinkAnswer}
                validateVideoLinkAnswer={this.validateVideoLinkAnswer}
                handleQuestionChange={this.handleQuestionChange}
                handleCustomAnswerChange={this.handleCustomAnswerChange}
                requiredQuestionsErrors={this.state.requiredQuestionsErrors}
                custom_answers={this.state.custom_answers}
                isInternalCandidate={this.state.isInternalCandidate}
                schoolPreferencesAnswer={this.state.schoolPreferencesAnswer}
                schoolPreferenceQuestionChoices={this.getSchoolPreferencesQuestionChoices(
                  currentRole
                )}
                handlePreferredSchoolToggle={this.handlePreferredSchoolToggle}
                handleInterestedInAllOpportunitiesToggle={
                  this.handleInterestedInAllOpportunitiesToggle
                }
                handleOpenToOtherLocationsChange={this.handleOpenToOtherLocationsChange}
              />
            </div>

            <div className="application-section bottom-section step-2">
              <div className="bottom-section-content">
                <h4 className="section-title">You’re almost there!</h4>
                <div className="mt2">
                  <input
                    id="go-back"
                    className="submit disabled btn-lrg go-back mr2 pointer"
                    type="submit btn-lrg"
                    value={this.state.backToProfileText}
                    onClick={() => {
                      const queryString = `?${window.location.search.substr(1)}&back`;
                      this.props.history.push(`/application/${this.state.job_id}/${queryString}`);
                    }}
                    onChange={() => this.props.history.push(`/application/${this.state.job_id}`)}
                  />
                  <input
                    id="submit"
                    className={`submit btn-lrg`}
                    type="submit"
                    value="Save & view draft"
                    disabled={this.state.showSpinner}
                  />

                  {(this.state.requiredQuestionsErrors.length > 0 ||
                    Object.keys(this.state.uploadErrorMessages).length > 0) && (
                    <h4
                      className="ml1"
                      style={{ marginTop: '1.2rem', maxWidth: '640px', lineHeight: '1.5' }}
                    >
                      <ErrorText
                        message={`Your responses have been saved, but you've missed some required items above.
                        You won't be able to preview and submit until all required items on this page are complete.`}
                        color="#000000"
                      />
                    </h4>
                  )}

                  {this.state.showSpinner && (
                    <LoadingSpinner
                      margin={0}
                      fontSize={2}
                      message="This may take a few seconds."
                      additionalClassname="bottom"
                    />
                  )}
                </div>
              </div>
              <Subfooter />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ApplicationDocumentationContainer);
