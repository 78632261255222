import { Alert as AlertMUI } from '@mui/material';
import { AlertInterface } from './types';

export const Alert: React.FC<AlertInterface> = ({
  children,
  dataTestId,
  type = 'info',
  variant,
  icon,
  sx,
}) => {
  return (
    <AlertMUI data-testid={dataTestId} icon={icon} variant={variant} severity={type} sx={sx}>
      {children}
    </AlertMUI>
  );
};
