import styled from 'styled-components';

import { DiamondIcon, StarIcon, RefreshIcon, FlameIcon, ThumbsUpGreenIcon } from 'ui-kit/icons';
import { ATSDistrictHomeDataTestIds } from '../../data-testids/ATS';

const nudgeFields = [
  {
    value: 'top_candidate',
    label: 'Highly relevant skills',
    icon: StarIcon,
    dataTestId: ATSDistrictHomeDataTestIds.HIGHLY_SKILLS_TEXT,
  },
  {
    value: 'high_retention',
    label: 'High retention potential',
    icon: RefreshIcon,
    dataTestId: ATSDistrictHomeDataTestIds.HIGH_RETENTION,
  },
  { value: 'preferenced_school', label: 'Preferenced your school', icon: ThumbsUpGreenIcon },
];

// nudge_order_value contains the strings used to identify nudges in the nudge_order field.
const randomizedNudges = [
  {
    value: 'is_popular',
    nudge_order_value: 'popular',
    label: 'Popular candidate',
    icon: FlameIcon,
  },
  {
    value: 'flexible_school_preferences',
    nudge_order_value: 'flexible',
    label: 'Flexible school preferences',
    icon: ThumbsUpGreenIcon,
  },
  {
    value: 'is_in_shortage_area',
    nudge_order_value: 'shortage',
    label: 'Shortage area',
    icon: DiamondIcon,
  },
];

export default function Nudges({ candidate, onHomepage = false, onFullProfile = false }) {
  let nudgesToShow = [];
  let nudgesCount = 0;

  // We show a max of 3 nudges at a time. They are ordered by importance so once we have
  // 3 nudges we can stop adding more to the list.
  nudgeFields.forEach(field => {
    if (nudgesCount >= 3) {
      return;
    }
    if (candidate[field.value] === true) {
      nudgesToShow.push(field);
      nudgesCount += 1;
    }
  });

  // If there is still room left over (< 3 nudges shown), use the nudge_order field
  // to go through the remaining nudges and apply them as necessary.
  // candidate.nudge_order will be undefined for district users and above, so check
  // it's there before proceeding.
  if (nudgesCount < 3 && candidate.nudge_order) {
    candidate.nudge_order.split(',').forEach(nudge => {
      if (nudgesCount >= 3) {
        return;
      }
      // special case: if preferenced school is true, skip flexible preferences nudge
      if (candidate['preferenced_school'] === true && nudge === 'flexible') {
        return;
      }
      const nudgeObject = randomizedNudges.find(n => n.nudge_order_value === nudge);
      if (candidate[nudgeObject.value] === true) {
        nudgesToShow.push({ ...nudgeObject });
        nudgesCount += 1;
      }
    });
  }

  return (
    <Container homepage={onHomepage} fullProfile={onFullProfile}>
      {nudgesToShow.map(nudge => {
        const Icon = nudge.icon;
        return (
          <NudgeRow key={nudge.value}>
            <Icon />
            <NudgeLabel data-testid={nudge.dataTestId}>{nudge.label}</NudgeLabel>
          </NudgeRow>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  ${({ fullProfile }) => (fullProfile ? '' : 'grid-column: 1 / span 2;')}

  ${({ homepage }) =>
    homepage
      ? `
    margin-top: 12px;
    padding-left: 14px;
  `
      : ''}
`;

const NudgeRow = styled.div`
  display: grid;
  grid-template-columns: 21px 1fr;
  grid-gap: 7px;
  align-items: center;

  height: 30px;
`;

NudgeRow.displayName = 'NudgeRow';

const NudgeLabel = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #393c49;
`;

NudgeLabel.displayName = 'NudgeLabel';
