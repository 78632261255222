import { useState, useEffect } from 'react';

import jobboardsAPI from 'api/jobboardsAPI';
import {
  JobAlertsBanner,
  JobAlertsBannerText,
  JobAlertsBannerCloseButton,
} from './JobAlertsBanner';
import { JobAlertsSubscribeModal } from './JobAlertsModal';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ATSDistrictJobListAlertBannerDataTestId } from 'data-testids/ATS';
export const JobAlerts = ({
  categories,
  jobboardName,
  jobboardSlug,
  emailToken,
  editPreferences,
}) => {
  const [modeState, setModeState] = useState({
    showSubscribeToJobAlertsBanner: !editPreferences,
    showSubscribeToJobAlertsModal: editPreferences,
    showSuccessfullySubscribedToJobAlertsBanner: false,
  });
  const [emailState, setEmailState] = useState({
    email: '',
    error: undefined,
  });
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const history = useHistory();
  const match = useRouteMatch('/jobs/state/:id');
  const rawStateCode = match?.params.id;
  const stateCode = rawStateCode?.toUpperCase();

  const onSubscribeToJobAlertsBannerClick = () => {
    setModeState(prevState => ({ ...prevState, showSubscribeToJobAlertsModal: true }));
  };

  const onHideSubscribeToJobAlertsBannerClick = () => {
    setModeState(prevState => ({ ...prevState, showSubscribeToJobAlertsBanner: false }));
  };

  const onSuccessfullySubscribedToJobAlertsBannerClick = () => {
    setModeState(prevState => ({
      ...prevState,
      showSuccessfullySubscribedToJobAlertsBanner: false,
    }));
  };

  const onJobAlertsSubscribeModalClose = () => {
    setModeState(prevState => ({ ...prevState, showSubscribeToJobAlertsModal: false }));
  };

  const onSubscribeToJobAlerts = () => {
    jobboardsAPI
      .subscribe(jobboardSlug, emailState.email, selectedSubcategories)
      .then(response => {
        setModeState(prevState => ({
          ...prevState,
          showSubscribeToJobAlertsBanner: false,
          showSubscribeToJobAlertsModal: false,
          showSuccessfullySubscribedToJobAlertsBanner: true,
        }));
      })
      .catch(error => setEmailState(prevState => ({ ...prevState, error: error.message })));
  };

  const onUnsubscribeToJobAlerts = unSubscribeFromAll => {
    if (emailToken) {
      jobboardsAPI
        .unsubscribe(jobboardSlug, emailToken, unSubscribeFromAll)
        .then(() => {
          setModeState(prevState => ({
            ...prevState,
            showSubscribeToJobAlertsBanner: true,
            showSubscribeToJobAlertsModal: false,
            showSuccessfullySubscribedToJobAlertsBanner: false,
          }));
          setEmailState({ email: '', error: undefined });
        })
        .then(() => history.push(`/jobs/${jobboardSlug}`));
    }
  };

  // Fetch subcatecories based on user supplied email token
  useEffect(() => {
    if (emailToken) {
      jobboardsAPI.subscriptions(jobboardSlug, emailToken).then(response => {
        setEmailState({ email: response.email });
        setSelectedSubcategories(response.subcategories.map(({ id }) => id));
      });
    }
  }, [jobboardSlug, emailToken]);

  return (
    <>
      {modeState.showSubscribeToJobAlertsBanner && (
        <JobAlertsBanner data-testid={ATSDistrictJobListAlertBannerDataTestId.ALERT_BANNER}>
          <JobAlertsBannerText tabIndex={0} onClick={onSubscribeToJobAlertsBannerClick}>
            <div>{`Subscribe to job alerts for ${
              stateCode === 'IN' ? 'the Indiana Educator Job Board' : jobboardName
            }\xa0\u2192`}</div>
          </JobAlertsBannerText>
          <JobAlertsBannerCloseButton
            tabIndex={1}
            onClick={onHideSubscribeToJobAlertsBannerClick}
          />
        </JobAlertsBanner>
      )}
      {modeState.showSuccessfullySubscribedToJobAlertsBanner && (
        <JobAlertsBanner tabIndex={0} onClick={onSuccessfullySubscribedToJobAlertsBannerClick}>
          <JobAlertsBannerText>{`Successfully subscribed!`}</JobAlertsBannerText>
          <JobAlertsBannerCloseButton />
        </JobAlertsBanner>
      )}
      <JobAlertsSubscribeModal
        isOpen={modeState.showSubscribeToJobAlertsModal}
        onClose={onJobAlertsSubscribeModalClose}
        onSubscribeToJobAlerts={onSubscribeToJobAlerts}
        onUnsubscribeToJobAlerts={onUnsubscribeToJobAlerts}
        jobboardName={jobboardName}
        categories={categories}
        jobboardSlug={jobboardSlug}
        emailState={emailState}
        setEmailState={setEmailState}
        selectedSubcategories={selectedSubcategories}
        setSelectedSubcategories={setSelectedSubcategories}
        isEditing={editPreferences && emailState.email}
      />
    </>
  );
};
