import { Component } from 'react';

import PropTypes from 'prop-types';

import ApplicationStatusModal from './ApplicationStatusModal';
import { appStatusType, colorMapping } from '../../utils/enums';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { SettingsRow } from 'components/SettingsRow';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DeletionModal } from 'sharedComponents/Modal/DeletionModal';

export default class ApplicationStatusRow extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    status: PropTypes.object.isRequired,
    emailTemplateList: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    isPreHiringPool: PropTypes.bool.isRequired,
  };

  state = {
    showApplicationStatusModal: false,
    showDeletionModal: false,
  };

  saveRow = statusObj => {
    this.setState({ showApplicationStatusModal: false });
    this.props.onSave(statusObj);
  };

  openDeleteModal = e => {
    e.stopPropagation();
    this.setState({ showDeletionModal: true });
  };

  deleteRow = () => {
    this.setState({ showDeletionModal: false });
    this.props.onDelete(this.props.status.id);
  };

  renderStatusSubtext = () => {
    let status = this.props.status;
    let view = status.school_admin_view_access;
    let move = status.school_admin_move_access;

    if (!view && !move) return null;

    let str = '';
    if (status.status_type === appStatusType.hiring_pool) {
      if (status.school_admin_move_district) {
        str += 'School admins can see & move candidates for the district';
      } else {
        str += 'School admins can see candidates & move candidates for their schools';
      }
    } else if (
      status.status_type === appStatusType.hiring_pool ||
      (status.status_type === appStatusType.archived &&
        // pre-hiring-pool archived statuses behave like non-archived pre-hp statuses
        !this.props.isPreHiringPool)
    ) {
      if (status.school_admin_move_district && status.school_admin_move_access) {
        str += 'School admins can see & move candidates for their schools and the district';
      } else if (status.school_admin_move_district) {
        str += 'School admins can see & move candidates for the district';
      } else if (status.school_admin_move_access) {
        str += 'School admins can see & move candidates for their schools';
      } else {
        str += 'School admins can see candidates';
      }
    } else if (move) {
      str += 'School admins can see & move candidates';
    } else if (view) {
      str += 'School admins can see candidates';
    }
    if (status.email_bool) {
      // add hyphen to front of string if there's a template present
      str = ' - ' + str;
    }
    // otherwise add period to end
    str = str + '.';
    return str;
  };

  render() {
    const { status, index } = this.props;

    const statusCanBeMoved = !status.reserved;

    return (
      <div>
        <SettingsRow
          sx={{
            margin: '10px 0 0 0',
            backgroundColor: status.reserved ? 'initial' : 'white',
            border: status.reserved ? '1px solid #909090' : 'inherit',
          }}
          groupCTA={
            <>
              <EditButton />
              {statusCanBeMoved && <DeleteButton onClick={this.openDeleteModal} />}
            </>
          }
          onClick={() => this.setState({ showApplicationStatusModal: true })}
        >
          <div className="vertical-flex">
            <div className="status-label">
              {status.label}
              <div className={`status-label-circle ${colorMapping[status.color]}`} />
            </div>
            <div className="grey mt0">
              {status.emailtemplate ? (
                <span className="underline">{status.emailtemplate.title}</span>
              ) : status.email_bool ? (
                <span className="underline">Blank email</span>
              ) : null}
              {this.renderStatusSubtext()}
            </div>

            <div className="scorecard-question-arrow-div">
              {statusCanBeMoved && (
                <div className="vertical-flex">
                  <IconButton
                    sx={{ height: '9px', width: '9px' }}
                    onClick={e => this.props.onMove(index, -1, e)}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                  <IconButton
                    sx={{ height: '9px', width: '9px' }}
                    onClick={e => this.props.onMove(index, 1, e)}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </SettingsRow>
        {this.state.showApplicationStatusModal && (
          <ApplicationStatusModal
            status={status}
            show={this.state.showApplicationStatusModal}
            onHide={() => this.setState({ showApplicationStatusModal: false })}
            emailTemplateList={this.props.emailTemplateList}
            onSave={this.saveRow}
            newApplicationStatuses={this.props.newApplicationStatuses}
            isPreHiringPool={this.props.isPreHiringPool}
          />
        )}
        {this.state.showDeletionModal && (
          <DeletionModal
            onClose={() => this.setState({ showDeletionModal: false })}
            onDelete={this.deleteRow}
            open={this.state.showDeletionModal}
            text="If you delete this status, all candidates in this status will be moved to the preceding active status. In addition, any job closure notifications using this status will be deleted."
            title="Are you sure?"
          />
        )}
      </div>
    );
  }
}
