import './gradesDropdown.css';

import { Component } from 'react';
import JobEditSubjects from '../../JobEdit/jobeditsubjects';
import PropTypes from 'prop-types';
import { ATSDistrictJobsListFiltersDataTestIds } from 'data-testids/ATS';

export default class SubjectsDropdown extends Component {
  static propTypes = {
    subjects: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.object),
    updateMultiSelect: PropTypes.func,
    awaitingRefresh: PropTypes.bool,
    refresh: PropTypes.func,
  };

  state = {
    showingSubjects: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.node && this.node.contains(event.target)) {
      // Inside click
      return;
    }

    this.setState({
      showingSubjects: false,
    });

    // If click outside the dropdown and awaiting a debounced search call,
    // prompt an immediate search (not debounced)
    if (this.props.awaitingRefresh) {
      this.props.refresh();
    }
  };

  render() {
    return (
      <div ref={node => (this.node = node)} className="dropdown subjects" id="valueItemDrop">
        <button
          className="selectbox"
          id="dLabel"
          data-testid={ATSDistrictJobsListFiltersDataTestIds.CATEGORY_FILTER}
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() => {
            this.setState(prevState => ({
              showingSubjects: !prevState.showingSubjects,
            }));
          }}
        >
          {this.props.isRequisition ? 'Job Category' : 'Categories'}
        </button>
        {this.state.showingSubjects && (
          <ul className="dropdown-menu-grades" aria-labelledby="dLabel">
            <JobEditSubjects
              subjects={this.props.subjects}
              categories={this.props.categories}
              onChange={values => this.props.updateMultiSelect(values, 'subjects')}
            />
          </ul>
        )}
      </div>
    );
  }
}
