export enum ComponentsJobEditGradesDataTestIds {
  EARLY_CHILDHOOD_BUTTON = 'components-job-edit-grades-early-childhood-button',
  ELEMENTARY_SCHOOL_BUTTON = 'components-job-edit-grades-elementary-school-button',
  MIDDLE_SCHOOL_BUTTON = 'components-job-edit-grades-middle-school-button',
  HIGH_SCHOOL_BUTTON = 'components-job-edit-grades-high-school-button',
}

export enum ComponentsLocationPreferencesDataTestIds {
  MILES_WITHIN_SELECT = 'components-location-preferences-miles-within-select',
  MILES_OF_INPUT = 'components-location-preferences-miles-of-input',
}

export enum ComponentsDeleteModalDataTestIds {
  AGREE_AND_CONTINUE_BUTTON = 'components-delete-modal-agree-and-continue-button',
}

export enum ComponentsAlertDialogDataTestIds {
  PRIMARY_BUTTON = 'components-alert-dialog-primary-button',
}

export enum ComponentsBaseModalDataTestIds {
  MODAL_CONTAINER = 'components-base-modal-container',
}

// Please don't use these directly in E2E tests, refer to the e2e/helpers/multiSelect.ts file
export enum ComponentsMultiSelectDataTestIds {
  SELECT_ALL_BUTTON = 'components-dropdown-select-all-button',
}
