import { useEffect } from 'react';

export const useDynamicMetaTags = (title: string, description: string): void => {
  useEffect(() => {
    document.title = title;
    const metaDescription = document.querySelector<HTMLMetaElement>('meta[name="description"]');

    if (metaDescription) {
      metaDescription.content = description;
    } else {
      const metaTag = document.createElement('meta');
      metaTag.name = 'description';
      metaTag.content = description;
      document.head.appendChild(metaTag);
    }

    return () => {
      document.title = 'Nimble';
      const metaDescription = document.querySelector<HTMLMetaElement>('meta[name="description"]');
      if (metaDescription) {
        metaDescription.remove();
      }
    };
  }, [title, description]);
};
