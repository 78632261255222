import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AltModal, Button } from 'ui-kit';
import { ComponentsAlertDialogDataTestIds } from '../data-testids/Components';

export default function AlertDialog({
  isOpen,
  onClose,
  text,
  buttonText = 'OK',
  title = 'Heads up',
}) {
  return (
    <AltModal.AltModal isOpen={isOpen} onClose={onClose} closeOnClickOutside={true}>
      <StyledAltModalTitle>{title}</StyledAltModalTitle>
      <StyledAltModalBody>{text}</StyledAltModalBody>
      <StyledActions>
        <Button
          variant="primary"
          onClick={onClose}
          data-testid={ComponentsAlertDialogDataTestIds.PRIMARY_BUTTON}
        >
          {buttonText}
        </Button>
      </StyledActions>
    </AltModal.AltModal>
  );
}

AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

const StyledAltModalTitle = styled(AltModal.Title)`
  font-size: 20px;
`;

const StyledAltModalBody = styled(AltModal.Body)`
  font-size: 19px;
`;

const StyledActions = styled(AltModal.Actions)`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 100%;
  padding: 30px;

  & button {
    max-height: 50px;
  }

  & button:first-child {
    grid-column: 1 / 1;
  }

  & button:last-child {
    grid-column: 3 / 3;
  }
`;
