import { useState } from 'react';

import { Content, Container, SpinnerContainer, Top } from './styles';
import { JobViewV2Header } from './components/JobViewV2Header';
import { JobViewV2Information } from './components/JobViewV2Information';
import { JobViewV2Metadata } from './components/JobViewV2Metadata';
import { JobViewV2Props, ShouldShow } from './types';
import { extractTextFromHTML, shouldShowJobViewField } from './utils';
import auth from 'utils/auth';
import CircularProgress from '@mui/material/CircularProgress';
import ShareToSocialMedia from 'components/ShareToSocialMedia';
import { useDynamicMetaTags } from 'hooks/useDynamicMetadata';

export const JobViewV2: React.FC<JobViewV2Props> = ({
  isInternalCandidate,
  isLoading,
  isPreview,
  job,
  onSubmit,
  toggleJobStatus,
}) => {
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const currentUser = auth.getUser();
  const shouldShow: ShouldShow = {
    internalNotes: shouldShowJobViewField(job, 'internal_role_notes'),
    attachments: shouldShowJobViewField(job, 'requiredapplicationattachment_set'),
    description: shouldShowJobViewField(job, 'description'),
    benefits: shouldShowJobViewField(job, 'benefits'),
  };

  const shouldShowJobViewV2Information: boolean =
    shouldShow.internalNotes ||
    shouldShow.attachments ||
    shouldShow.description ||
    shouldShow.benefits;

  const htmlTitle = `${job.title} at ${job.district.name} - Nimble`;
  const beginningOfDescription = extractTextFromHTML(job.description);
  useDynamicMetaTags(htmlTitle, beginningOfDescription);

  return (
    <Container data-testid="jobview-v2">
      <Top component="header">
        {isLoading && (
          <SpinnerContainer data-testid="loading-spinner">
            <CircularProgress />
          </SpinnerContainer>
        )}
        {!isLoading && (
          <JobViewV2Header
            handleOpenShareModal={() => setShouldShowShareModal(true)}
            isPreview={isPreview}
            job={job}
            userDistrict={currentUser?.profile.district?.id}
            onSubmit={onSubmit}
            isInternalCandidate={isInternalCandidate}
            toggleJobStatus={toggleJobStatus}
          />
        )}
      </Top>
      <Content>
        {shouldShowJobViewV2Information && (
          <JobViewV2Information
            shouldShow={shouldShow}
            job={job}
            isInternalCandidate={isInternalCandidate}
          />
        )}
        <JobViewV2Metadata job={job} />
      </Content>

      {shouldShowShareModal && (
        <ShareToSocialMedia
          isOpen={shouldShowShareModal}
          onClose={() => {
            setShouldShowShareModal(false);
          }}
          roleToShare={job}
        />
      )}
    </Container>
  );
};
