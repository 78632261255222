import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { theme, DatePicker } from 'ui-kit';
import { useOnClickOutside } from 'hooks';
import { DropdownFeatures, RoleStartDateOptions} from 'utils/constants';

import {
  Container,
  DownCaret,
  Dropdown,
  Option,
  SelectionList,
} from '../StateJobBoardSharedComponents';

export function StartDateFilterDropDown({
  filterValues,
  actions,
  isActive,
  setIsActive,
  mainColor = theme.uiColors.black,
  highlightColor = theme.colors.primary.light,
  dataTestId,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [beforeDatePickerFocused, setBeforeDatePickerFocused] = useState(false);
  const [afterDatePickerFocused, setAfterDatePickerFocused] = useState(false);

  const closeNodeOnClickOutside = useOnClickOutside(() => setShowOptions(false), showOptions);

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  };

  const toggleValue = (e, value) => {
    let updatedValues = [...filterValues.startDate];

    if (!e.target.checked) {
      updatedValues = updatedValues.filter(item => item !== value);
    } else {
      updatedValues.push(value);
      // ensure no duplicates
      updatedValues = [...new Set(updatedValues)];
    }

    updateStartDateFilter(updatedValues);
  };

  const toggleBefore = (e, value) => {
    let updatedValues = [...filterValues.startDate];

    if (!e.target.checked) {
      updatedValues = updatedValues.filter(item => item !== value);

      // startDateBefore should be null if this box is unchecked
      actions.setStartDateBefore(null);
    } else {
      updatedValues.push(value);
      // ensure no duplicates
      updatedValues = [...new Set(updatedValues)];

      // set default date when this box is checked
      actions.setStartDateBefore(moment(new Date()));
    }

    updateStartDateFilter(updatedValues);
  };

  const toggleAfter = (e, value) => {
    let updatedValues = [...filterValues.startDate];

    if (!e.target.checked) {
      updatedValues = updatedValues.filter(item => item !== value);

      // startDateAfter should be null if this box is unchecked
      actions.setStartDateAfter(null);
    } else {
      updatedValues.push(value);
      // ensure no duplicates
      updatedValues = [...new Set(updatedValues)];

      // set default date when this box is checked
      actions.setStartDateAfter(moment(new Date()));
    }

    updateStartDateFilter(updatedValues);
  };

  const updateStartDateFilter = updatedValues => {
    // Per spec, the filter should not show as "active"
    // if only "Immediate" and "Next school year" are
    // selected. It should also not show as "active"
    // if no options are selected.
    const immediateAndNextYearOnly =
      updatedValues.length === 2 &&
      updatedValues.includes('immediate') &&
      updatedValues.includes('next_school_year');
    const noneSelected = updatedValues.length === 0;
    const isActive = !immediateAndNextYearOnly && !noneSelected;

    actions.setStartDate(updatedValues);
    setIsActive(isActive);
  };

  return (
    <Container highlightColor={highlightColor} isActive={isActive} ref={closeNodeOnClickOutside}>
      <Dropdown onClick={toggleOptions} data-testid={dataTestId}>
        <p>Start date</p>
        <DownCaret fillColor={mainColor} />
      </Dropdown>
      <StyledSelectionList showOptions={showOptions}>
        <Option>
          <label className="container" data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${RoleStartDateOptions.IMMEDIATE}`}>
            <input
              type="checkbox"
              checked={filterValues.startDate.includes('immediate')}
              onChange={e => toggleValue(e, 'immediate')}
              data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${RoleStartDateOptions.IMMEDIATE}`}
            />
            <span className="checkmark"></span>
            Immediate
          </label>
        </Option>
        <Option data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${RoleStartDateOptions.NEXT_SCHOOL_YEAR}`}>
          <label className="container">
            <input
              type="checkbox"
              checked={filterValues.startDate.includes('next_school_year')}
              onChange={e => toggleValue(e, 'next_school_year')}
              data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${RoleStartDateOptions.NEXT_SCHOOL_YEAR}`}
            />
            <span className="checkmark"></span>
            Next school year
          </label>
        </Option>
        <Option>
          <label className="container" data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${RoleStartDateOptions.BEFORE}`}>
            <input
              type="checkbox"
              checked={filterValues.startDate.includes('before')}
              onChange={e => toggleBefore(e, 'before')}
              data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${RoleStartDateOptions.BEFORE}`}
            />
            <span className="checkmark"></span>
            Before:
          </label>
          <DatePickerContainer>
            <DatePicker
              date={filterValues.startDateBefore}
              onDateChange={date => {
                if (date) {
                  actions.setStartDateBefore(date);
                  // If choosing a date, the "Before" checkbox should
                  // be checked automatically
                  toggleValue({ target: { checked: true } }, 'before');
                } else {
                  actions.setStartDateBefore(null);
                  toggleValue({ target: { checked: false } }, 'before');
                }
              }}
              focused={beforeDatePickerFocused}
              onFocusChange={({ focused }) => setBeforeDatePickerFocused(focused)}
              id="state-jobboard-start-date-before"
            />
          </DatePickerContainer>
        </Option>
        <Option>
          <label className="container" data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${RoleStartDateOptions.AFTER}`}>
            <input
              type="checkbox"
              checked={filterValues.startDate.includes('after')}
              onChange={e => toggleAfter(e, 'after')}
              data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${RoleStartDateOptions.AFTER}`}
            />
            <span className="checkmark"></span>
            After:
          </label>
          <DatePickerContainer>
            <DatePicker
              date={filterValues.startDateAfter}
              onDateChange={date => {
                if (date) {
                  actions.setStartDateAfter(date);
                  // If choosing a date, the "After" checkbox should
                  // be checked automatically
                  toggleValue({ target: { checked: true } }, 'after');
                } else {
                  actions.setStartDateAfter(null);
                  toggleValue({ target: { checked: false } }, 'after');
                }
              }}
              focused={afterDatePickerFocused}
              onFocusChange={({ focused }) => setAfterDatePickerFocused(focused)}
              id="state-jobboard-start-date-after"
            />
          </DatePickerContainer>
        </Option>
      </StyledSelectionList>
    </Container>
  );
}

StartDateFilterDropDown.propTypes = {
  filterValues: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
  highlightColor: PropTypes.string,
};

const StyledSelectionList = styled(SelectionList)`
  overflow: visible;
`;

const DatePickerContainer = styled.div`
  .SingleDatePickerInput__withBorder {
    height: 38px;
    margin-left: 24px;
    margin-bottom: 10px;
  }
  .DateInput_input {
    height: 36px;
    padding-left: 10px;
  }
  .SingleDatePickerInput_calendarIcon {
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;
