import { Box } from '@mui/material';
import { JobLink, PublicShareInfoText, PublicShareLinkContainer, SectionTitle } from './styles';
import { JobViewV2PublicShareLinkProps } from '../types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { nimbleTheme } from 'theme';
import { TooltipButtonClickTrigger } from 'sharedComponents/Buttons/TooltipButtonClickTrigger';

export const JobViewV2PublicShareLink: React.FC<JobViewV2PublicShareLinkProps> = ({ link }) => {
  const handleClick = () => {
    return navigator.clipboard.writeText(link);
  };

  return (
    <Box data-testid="public-share-link-section">
      <SectionTitle variant="h2">Public share link</SectionTitle>
      <PublicShareInfoText data-testid="public-share-info">
        This link will take users to the publicly available listing for this role. They don’t need a
        Nimble account to access.
      </PublicShareInfoText>
      <PublicShareLinkContainer>
        <JobLink value={link} data-testid="job-link" />
        <TooltipButtonClickTrigger
          onClick={handleClick}
          variant="text"
          dataTestId="copy-to-clipboard-button"
          startIcon={<ContentCopyIcon fontSize="small" />}
          tooltipText="Copied!"
          buttonSx={{
            borderLeft: `1px solid ${nimbleTheme.palette.gray.darker}`,
            borderRadius: 0,
            color: nimbleTheme.palette.text.primary,
            fontSize: 'body1.fontSize',
            fontWeight: nimbleTheme.typography.fontWeightBold,
            lineHeight: nimbleTheme.spacing(3),
            padding: nimbleTheme.spacing(1.5),
          }}
        >
          Copy
        </TooltipButtonClickTrigger>
      </PublicShareLinkContainer>
    </Box>
  );
};
