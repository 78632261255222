import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Nudges from './Nudges';
import { MoveLeftIcon, MoveRightIcon, RightCaretWhiteIcon } from 'ui-kit/icons';
import { ATSDistrictHomeDataTestIds } from '../../data-testids/ATS';

export default function TopCandidateCard({
  candidate,
  showMoveLeftIcon,
  showMoveRightIcon,
  onMoveLeft,
  onMoveRight,
  ignoreTopCandidate,
}) {
  const candidateProfileUrl = new URL(candidate.profile_url);
  const candidatePath = candidateProfileUrl.pathname;
  const candidateQueryParameters = candidateProfileUrl.search;

  return (
    <Card data-testid={ATSDistrictHomeDataTestIds.TOP_CANDIDATE_CONTAINER}>
      <Name data-testid={ATSDistrictHomeDataTestIds.TOP_CANDIDATE_NAME_TEXT}>{candidate.name}</Name>
      <JobTitle data-testid={ATSDistrictHomeDataTestIds.TOP_CANDIDATE_JOB_TEXT}>
        {candidate.job_title}
      </JobTitle>
      <Nudges candidate={candidate} onHomepage={true} />
      <IgnoreButton onClick={() => ignoreTopCandidate(candidate.id)}>Ignore</IgnoreButton>
      <Link
        rel="noopener noreferrer"
        to={{
          pathname: candidatePath,
          search: candidateQueryParameters,
        }}
        target="_blank"
        style={{
          gridColumn: '2 / span 1',
          gridRow: '4 / span 1',
        }}
      >
        <ViewProfileButton>
          View profile&nbsp;&nbsp;
          <RightCaretWhiteIcon />
        </ViewProfileButton>
      </Link>
      {showMoveLeftIcon && (
        <MoveLeftButton>
          <MoveLeftIcon onClick={onMoveLeft} />
        </MoveLeftButton>
      )}
      {showMoveRightIcon && (
        <MoveRightButton>
          <MoveRightIcon onClick={onMoveRight} />
        </MoveRightButton>
      )}
    </Card>
  );
}

const Card = styled.div`
  width: 344px;
  height: 246px;
  background-color: #ffffff;
  border: 1px solid #eeeff2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.095);
  border-radius: 3px;

  padding: 18px 21px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-template-rows: 23px 23px 1fr 32px;

  position: relative;
`;

const Name = styled.p`
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;

  font-size: 15px;
  font-weight: 600;
  color: #393c49;
`;

const JobTitle = styled.p`
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;

  font-size: 14px;
  font-weight: 400;
  color: #a6a7ad;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.button`
  height: 31px;
  border-radius: 2px;
  font-size: 15px;
`;

const IgnoreButton = styled(Button)`
  border: 2px solid #a6a7ad;
  color: #a6a7ad;
  background-color: #ffffff;

  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
`;

const ViewProfileButton = styled(Button)`
  background: #304b7d;
  border: 2px solid #304b7d;
  color: #ffffff;
  width: 100%;
`;

const MoveButton = styled.div`
  position: absolute;
  top: 116px;

  cursor: pointer;
`;

const MoveLeftButton = styled(MoveButton)`
  left: -19px;
`;

const MoveRightButton = styled(MoveButton)`
  right: -19px;
`;
