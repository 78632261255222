import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import JobRow from './JobRow';
import JobRowRequisition from './JobRowRequisition';
import RolesSearchBar from '../DistrictJobsList/RolesSearchBar';
import Subfooter from '../subfooter_poweredby.js';
import LoadingSpinner from 'components/loadingSpinner';

import sortIcon from '../../assets/icon_sort_order.svg';
import './requisition-jobs-list.css';
import '../ListViews/listviews.css';
import styled from 'styled-components';
import InfoIcon from 'assets/icons/icon_info.svg';
import { ATSDistrictJobListDataTestIds } from 'data-testids/ATS';

export default class JobsList extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
    query: PropTypes.string,
    queryUpdate: PropTypes.func.isRequired,
    orderRoles: PropTypes.func.isRequired,
    sortBy: PropTypes.string,
    resetRoles: PropTypes.func.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.number),
    schools: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
    grades: PropTypes.arrayOf(PropTypes.number).isRequired,
    fulltime: PropTypes.arrayOf(PropTypes.number),
    updateMultiSelect: PropTypes.func,
    isRequisition: PropTypes.bool || false,
    isSchoolUser: PropTypes.bool || false,
    awaitingRefresh: PropTypes.bool,
    refresh: PropTypes.func,
    isInternalCandidate: PropTypes.bool,
    district: PropTypes.object,
    district_id: PropTypes.number,
    showSpinner: PropTypes.bool,
  };

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    let isRequisitionPage = this.props.isRequisition;
    const onDemo = window.location.host === 'demo.hirenimble.com';

    return (
      <div
        className={'jobs-list-component ' + (isRequisitionPage === true ? 'requisition-list' : '')}
      >
        <div className="jobs-list-component-requisition-count">
          {isRequisitionPage ? (
            <span>
              {this.props.roles.length} Posting{this.props.roles.length === 1 ? '' : 's'}
            </span>
          ) : null}
        </div>

        <RolesSearchBar
          district_id={this.props.district_id}
          query={this.props.query}
          setCurrentQuery={this.props.queryUpdate}
          subjects={this.props.subjects}
          schools={this.props.schools}
          categories={this.props.categories}
          grade={this.props.grade}
          fulltime={this.props.fulltime}
          resetRoles={this.props.resetRoles}
          updateMultiSelect={this.props.updateMultiSelect}
          grades={this.props.grades}
          isRequisition={isRequisitionPage}
          awaitingRefresh={this.props.awaitingRefresh}
          refresh={this.props.refresh}
        />
        <div className="list-view">
          <ReactTooltip delay-show="100" />
          <div className="list-header list-item">
            {/* Spacer element for row item without a heading */}
            {!isRequisitionPage && (
              <div
                className={`job-row-featured-icon ${onDemo ? 'demo' : ''}`}
                data-tip="Featured job"
              />
            )}
            <div
              className={'job-row-title pointer ' + (this.props.sortBy === 'title' ? 'active' : '')}
              onClick={() => this.props.orderRoles('title')}
              data-testid={ATSDistrictJobListDataTestIds.COLUMN_NAME}
            >
              {isRequisitionPage ? 'Posting title' : 'Job'}
              &nbsp;&nbsp;
              <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
            </div>
            {isRequisitionPage && (
              <div
                className={
                  'job-row-date pointer ' + (this.props.sortBy === 'created' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('created')}
              >
                Date Posted &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}
            {!isRequisitionPage && (
              <div
                className={
                  'job-row-school pointer ' + (this.props.sortBy === 'schools' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('schools')}
                data-testid={ATSDistrictJobListDataTestIds.COLUMN_NAME}
              >
                School(s) &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}
            {isRequisitionPage && (
              <div
                className={
                  'job-row-applicants new pointer ' +
                  (this.props.sortBy === 'app_count-new' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('app_count-new')}
              >
                New Applicants &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}

            {isRequisitionPage && (
              <div
                className={
                  'job-row-applicants in-progress pointer ' +
                  (this.props.sortBy === 'app_count-in_progress' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('app_count-in_progress')}
              >
                In Process Applicants &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}

            {isRequisitionPage && (
              <div
                className={
                  'job-row-applicants total pointer ' +
                  (this.props.sortBy === 'app_count-total' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('app_count-total')}
              >
                Total # of Applicants &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}

            {!isRequisitionPage && (
              <div
                className={
                  'job-row-subjects pointer ' + (this.props.sortBy === 'subjects' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('subjects')}
                data-testid={ATSDistrictJobListDataTestIds.COLUMN_NAME}
              >
                Job Category &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}
            {!isRequisitionPage && (
              <div
                className={
                  'job-row-salary pointer ' + (this.props.sortBy === 'salary_max' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('salary_max')}
                data-testid={ATSDistrictJobListDataTestIds.COLUMN_NAME}
              >
                Salary &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}
            {!isRequisitionPage && (
              <div
                className={
                  'job-row-date pointer ' + (this.props.sortBy === 'start_date' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles(isRequisitionPage ? '' : 'start_date')}
                data-testid={ATSDistrictJobListDataTestIds.COLUMN_NAME}
              >
                Start Date &nbsp;&nbsp;
                <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
              </div>
            )}
            {!isRequisitionPage && (
              <div
                className={
                  'job-row-date deadline pointer ' +
                  (this.props.sortBy === 'deadline' ? 'active' : '')
                }
                onClick={() => this.props.orderRoles('deadline')}
              >
                <DeadlineContainer data-testid={ATSDistrictJobListDataTestIds.COLUMN_NAME}>
                  Deadline
                  <>
                    <StyledInfoIcon
                      src={InfoIcon}
                      alt="deadline-tooltip"
                      data-tip={'All jobs close at 8PM Pacific Time <br/> on the deadline.'}
                      data-for="deadline-info"
                    />
                    <ReactTooltip id="deadline-info" effect="solid" multiline />
                  </>
                  <img src={sortIcon} className="icon-sort-order" alt="uppy-downy" />
                </DeadlineContainer>
              </div>
            )}
          </div>
          {isRequisitionPage ? (
            this.props.showSpinner ? (
              <LoadingSpinner />
            ) : (
              this.props.roles.map((role, index) => (
                <JobRowRequisition
                  job={role}
                  key={role.id}
                  isSchoolUser={this.props.isSchoolUser}
                  index={index}
                  district={this.props.district}
                />
              ))
            )
          ) : (
            this.props.roles.map(role => (
              <JobRow
                job={role}
                key={role.id}
                categories={this.props.categories}
                isInternalCandidate={this.props.isInternalCandidate}
              />
            ))
          )}
        </div>
        <Subfooter />
      </div>
    );
  }
}

const StyledInfoIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`;

const DeadlineContainer = styled.div`
  display: flex;
  column-gap: 4px;
`;
