import { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import StateJobRow from './StateJobRow';

import { NoResultsFoundMessageContainer, Row } from './StateJobBoardSharedComponents';
import { theme } from 'ui-kit';
import LoadingSpinner from 'components/loadingSpinner';

const StateJobsList = forwardRef(({ isLoading, results, count, fetchingNextPage }, ref) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(ReactTooltip.rebuild);

  return (
    <>
      <JobBoardContainer>
        <HeaderRow>
          <span>Position/Organization</span>
          <HideOnMobile>
            <span>School(s)</span>
          </HideOnMobile>
          <HideOnMobile>
            <span>Salary</span>
          </HideOnMobile>
          <HideOnMobile>
            <span>Type</span>
          </HideOnMobile>
          <HideOnMobile>
            <span>Start date</span>
          </HideOnMobile>
          <span>Deadline</span>
        </HeaderRow>
        {isLoading ? (
          <LoadingSpinner margin={2} fontSize={2} />
        ) : results.length < 1 ? (
          <NoResultsFoundMessageContainer>
            <div>No results found.</div>
            <div>Please try a different search term or adjust your filters.</div>
          </NoResultsFoundMessageContainer>
        ) : (
          <div>
            {results.map((role, idx) => {
              // Attach our ref to the element 20 positions from the bottom of
              // the list. When this element comes into view, start fetching
              // the next page. See the source of the ref in this component's
              // parent for more information.
              if (idx === results.length - 20) {
                return <StateJobRow key={role.id} role={role} ref={ref} index={idx}/>;
              } else {
                return <StateJobRow key={role.id} role={role} index={idx}/>;
              }
            })}
            {fetchingNextPage && <LoadingSpinner margin={2} fontSize={2} />}
          </div>
        )}
      </JobBoardContainer>
      <ReactTooltip id="state-jobboard-schools" effect="solid" html />
    </>
  );
});

export default StateJobsList;

const JobBoardContainer = styled.div`
  background-color: #ffffff;
  min-height: calc(100vh - 60px);

  padding: 24px;

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding: 30px 24px;
  }
`;

const BoldNote = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 30px;
`;

const HeaderRow = styled(Row)`
  height: 26px;

  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`;

const HideOnMobile = styled.div`
  display: none;

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: block;
  }
`;

const NoResultsFoundMessage = styled.div``;
