import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import ProfileTimeline from '../../Profile/ProfileTimeline/ProfileTimeline';
import Button from '../../common/Button';
import EmailModal from '../../EmailModal';
import FileViewer from '../../Profile/FileViewer';
import PhoneSection from './PhoneSection';
import SourceSection from './SourceSection';
import CustomTagSection from '../../CustomTag/CustomTagProfile/CustomTagSection';
import { handleOutsideClickOnValidForm } from 'utils/util';
import InternalCandidateNotes from '../../Profile/InternalCandidateNotes/InternalCandidateNotes';
import IconNotes from 'assets/icon_notes.svg';
import QPDrowdownButton from './QPDropdownButton';
import auth from 'utils/auth';
import ProspectStatusSwitcher from '../../Prospects/ProspectStatusSwitcher';
import { questionType } from 'utils/enums';
import UploadProspectDocumentModal from 'components/Prospects/UploadProspectDocumentModal';
import { uploadFileToUser } from 'utils/fileUpload';

import prospectDetailAPI from 'api/prospectDetailAPI';
import customTagAPI from 'api/customTagAPI';

import IconDownCaretWithSquare from 'assets/icon_down_caret_with_square.svg';
import uploadIcon from 'assets/icons/icon_upload.png';
import emailIcon from 'assets/icon-email.svg';
import { ReactComponent as VideoIcon } from 'assets/icon-video.svg';
import ConvertToCandidateModal from './ConvertToCandidate/ConvertToCandidateModal';
import { assignTag, removeTag, validateTagUserType } from '../../CustomTag/util';

export default class QuickProfile extends Component {
  constructor(props) {
    super(props);
    const fullUser = {
      ...props.partialUser,
      attachments: [],
      internal_candidate_notes: [],
    };
    this.state = {
      fullUser: fullUser,
      showActionDropdown: false,
      emailModalOpen: false,
      templateEmailModal: false,
      emailTemplate: {},
      subject: '',
      message: '',
      recipientList: [fullUser],
      /**** end status update section ****/
      events: [],
      fullApplication: {},
      uploadProspectDocumentModalOpen: false,
      conversionModalOpen: false,
      districtTags: [],
    };
    this.isSchoolUser = auth.isSchoolUser();
    this.isDistrictUser = auth.isDistrictUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
  }

  static propTypes = {
    partialUser: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    emailTemplatesList: PropTypes.array.isRequired,
    sources: PropTypes.array.isRequired,
    retrieveProspect: PropTypes.func,
    updateProspectStatus: PropTypes.func,
  };

  componentDidMount() {
    // Don't show footer on district/candidates when quickview open
    document.body.classList.add('quick-profile-open');
    // check for new tags
    customTagAPI.fetchCustomTags().then(tags => this.setState({ districtTags: tags }));

    this.fetchProspect();
    this.fetchEvents();
    this.fetchSources();
  }

  componentWillUnmount() {
    // Revert to prior classes
    document.body.classList.remove('quick-profile-open');
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  fetchProspect = () => {
    prospectDetailAPI.getProspect(this.props.partialUser.prospect_id).then(prospect => {
      if (!this.ignoreLastFetch) {
        this.setState({ fullUser: prospect });
      }
    });
  };

  fetchEvents = () => {
    axios.get(`/api/events/${this.props.partialUser.id}/`).then(r => {
      if (!this.ignoreLastFetch) {
        this.setState({
          events: r.data,
        });
      }
    });
  };

  fetchSources = () => {
    axios.get('/api/candidate-source/').then(r => this.setState({ sources: r.data }));
  };

  onHide = () => {
    this.setState({
      emailTemplate: {},
      subject: '',
      message: '',
      emailModalOpen: false,
      recipientList: [this.props.partialUser],
    });
  };

  openModal = modal => {
    this.setState({ [modal]: true });
  };

  updateInternalCandidateNote = async (newNote, recipientList, message) => {
    const fullUser = { ...this.state.fullUser };
    fullUser.internal_candidate_notes.unshift(newNote); // add new note to beginning of notes array
    this.setState({ fullUser });

    axios
      .patch(`/api/user/${this.props.partialUser.id}/`, {
        internalCandidateNote: newNote,
      })
      .then(() => {
        this.emailTaggedUsers(recipientList, message, 'note');
      });
  };

  emailTaggedUsers = (recipientList, message, type, id) => {
    // Type is either 'note' or 'scorecard'. For display purposes.
    const tagObjects = [];

    recipientList.forEach(recipient => {
      const url = this.buildUrl();
      const tagObj = {
        user_id: recipient.id,
        location: `in a ${type}`,
        note_url: url,
        note_copy: message,
        candidate_name: this.props.partialUser.name,
      };
      tagObjects.push(tagObj);
    });

    this.sendTagNotifications(tagObjects);
  };

  buildUrl = () => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;

    return `${location}/prospectlist?query=${this.props.partialUser.name.replace(' ', '+')}`;
  };

  sendTagNotifications = tagObjects => {
    return axios.post('/api/send_tag_notifications/', tagObjects).catch(err => console.log(err));
  };

  outsideClickListener = ev =>
    handleOutsideClickOnValidForm(this.node, ev, this.toggleApplicationCopyButton);

  toggleApplicationCopyButton = () => {
    if (!this.state.showActionDropdown) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, false);
    }
    const showActionDropdown = !this.state.showActionDropdown;
    this.setState({ showActionDropdown });
  };
  updateEmailTemplateSelection = e => {
    /** selected template will send up id as e.target.value. find in list */
    const emailTemplateProp = this.props.emailTemplatesList.find(
      template => template.id === Number(e.target.value)
    );

    if (emailTemplateProp) {
      let emailTemplate = Object.assign({}, emailTemplateProp);
      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }
      this.setState({
        emailTemplate,
        subject: emailTemplate.subject,
        message: emailTemplate.content,
      });
    }
    if (e.target.value === null) {
      this.setState({
        emailTemplate: {},
        subject: '',
        message: '',
      });
    }
  };

  renderConvertToCandidateButton = () => {
    const hasTalentMarketPlaceSource =
      this.state.fullUser.district_source?.label === 'Talent Marketplace';
    if (hasTalentMarketPlaceSource) {
      return <div />;
    } else
      return (
        <ConvertButton data-convert-button onClick={this.openProspectConversionModal}>
          Convert to Candidate
        </ConvertButton>
      );
  };

  internalCandidateNotes = marginTop => {
    /** InternalCandidatesNotes component is used twice in this component: in one place for
     * prospect list and another for candidates list. Put in separate function so you can just
     * call the function in the two places below.
     */
    return (
      <div className={`profile-section ${marginTop}`}>
        <h4 className="section-header mb1 mr1 inline-block">Internal Notes</h4>
        <div className="profile-section-icon-div">
          <img src={IconNotes} alt="internal notes" />
        </div>
        <InternalCandidateNotes
          notes={this.state.fullUser.internal_candidate_notes ?? []}
          updateNote={this.updateInternalCandidateNote}
          adminUsers={this.props.adminUsers}
        />
      </div>
    );
  };

  attachmentsSection = () => {
    let attachmentAnswers = [];
    let videoLinkAnswers = [];

    // Combine & sort attachment & video link questions
    if (this.state.fullApplication) {
      attachmentAnswers = this.state.fullApplication.applicationattachment_set || [];

      attachmentAnswers = attachmentAnswers.map(answer => {
        let question;
        if (answer.required_application_attachment) {
          question = this.state.fullApplication.role.requiredapplicationattachment_set.find(
            question => answer.required_application_attachment === question.id
          );
        }

        // Addtional documents don't have an associated question and therefore don't have order
        if (!question) {
          question = { order: -1 };
        }

        return { ...answer, question };
      });

      videoLinkAnswers = this.state.fullApplication.applicationattachmentvideourl_set || [];
      videoLinkAnswers = videoLinkAnswers.map(answer => {
        const question = answer.required_application_attachment;
        return { ...answer, question };
      });
    }

    const attachmentAndVideoLinkAnswers = [...attachmentAnswers, ...videoLinkAnswers].sort(
      (a, b) => a.question.order - b.question.order
    );

    return (
      <div className="profile-section attachments">
        <div className="flex mb1 files-download-container">
          <h4 className="section-header flex-1">Attached Files</h4>
          {this.isDistrictUser && (
            <img
              src={uploadIcon}
              alt="download-icon"
              className="pointer download-upload-icons upload-file"
              data-tip
              data-for="upload"
              data-place="bottom"
              data-delay-show="100"
              onClick={this.openDocumentUploadModal}
            />
          )}
          <ReactTooltip id="upload" effect="solid">
            <span style={{ color: 'white' }}>Upload file</span>
          </ReactTooltip>
        </div>

        {this.state.fullApplication &&
          attachmentAndVideoLinkAnswers.map((answer, index) => {
            if (answer.question && answer.question.question_type === questionType.videoLink) {
              return (
                <FileViewer
                  key={index}
                  file={answer.video_url}
                  title={answer.question.title}
                  icon={<VideoIcon />}
                />
              );
            } else if (
              answer.question &&
              answer.question.question_type === questionType.attachment
            ) {
              return (
                <FileViewer
                  key={index}
                  file={answer.attachment}
                  title={answer.question && answer.question.title}
                />
              );
            } else {
              // Additional uploaded documents
              return <FileViewer key={index} file={answer.attachment} title={answer.title} />;
            }
          })}
        {this.state.fullUser.attachments &&
          this.state.fullUser.attachments.map(file =>
            file.attachment ? (
              <FileViewer key={file.id} file={file.attachment} title={file.title} />
            ) : (
              ''
            )
          )}
      </div>
    );
  };

  openDocumentUploadModal = () => {
    this.setState({ uploadProspectDocumentModalOpen: true });
  };

  submitUserAttachment = async (file, fileName) => {
    return uploadFileToUser(this.props.partialUser.id, file, fileName);
  };

  getSkipEmailButton = () => (
    <button className="email-modal-button" onClick={this.onHide}>
      Skip Email
    </button>
  );

  getLastUpdated = () => {
    let updated = null;
    let updated_by = null;
    if (this.state.fullUser.modified) {
      updated = this.state.fullUser.modified;
      updated_by = this.state.fullUser.modified_by;
    }
    return [updated, updated_by];
  };

  openProspectConversionModal = () => {
    this.setState({ conversionModalOpen: true });
  };

  updateFullUser = (fieldName, value) => {
    const fullUser = { ...this.state.fullUser };
    fullUser[fieldName] = value;
    this.setState({ fullUser });
  };

  updateProspectStatus = (selectedStatus, prospect_id) => {
    const updateObject = { status: selectedStatus.value };
    // update prospect status in container state
    this.props.updateProspectStatus(selectedStatus, prospect_id);
    prospectDetailAPI.updateProspectPartial(prospect_id, updateObject).then(this.fetchEvents);
  };

  /**
   * Assign selected tag to the specified prospect.
   */
  addTag = async tagId => {
    await assignTag(tagId, this.state.fullUser);
    this.fetchProspect();
  };

  /**
   * Remove tag from the prospect
   */
  deleteTag = async tagId => {
    await removeTag(tagId, this.state.fullUser);
    this.fetchProspect();
  };

  render() {
    const { fullUser, fullApplication } = this.state;
    const { show } = this.props;
    const [updated, updated_by] = this.getLastUpdated();

    return (
      <div>
        <div className="quick-profile-fade" hidden={!show} onClick={this.props.toggleVisibility} />
        {show && (
          <div className="quick-profile-container">
            <div className="flex-1 quick-profile-inside">
              <CloseButton onClick={this.props.toggleVisibility}>×</CloseButton>
              <div className="default-card">
                <div className="profile-section one">
                  <div className="QuickProfile__header">
                    <div className="flex flex-center">
                      <div className="QuickProfile__header-name">
                        <h2>{this.props.partialUser.name}</h2>
                      </div>
                      <div className="QuickProfile__header-icons">
                        <div className="relative" ref={node => (this.node = node)}>
                          <img
                            className="QuickProfile__header-dropdown-img candidate-icon-field"
                            src={IconDownCaretWithSquare}
                            onClick={this.toggleApplicationCopyButton}
                            // eslint-disable-next-line react/no-unknown-property
                            fill="red"
                            alt=""
                          />
                          <div
                            className={`quickview-dropdown-div ${
                              this.state.showActionDropdown ? 'expanded' : ''
                            }`}
                          >
                            <QPDrowdownButton
                              label="Email"
                              onClick={() => this.setState({ emailModalOpen: true })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.conversionModalOpen && (
                    <ConvertToCandidateModal
                      isOpen={this.state.conversionModalOpen}
                      onClose={() => this.setState({ conversionModalOpen: false })}
                      fullUser={fullUser}
                    />
                  )}
                  {this.state.emailModalOpen && (
                    <EmailModal
                      recipientList={this.state.recipientList}
                      show={this.state.emailModalOpen}
                      subject={this.state.subject}
                      message={this.state.message}
                      onHide={this.onHide}
                      onSend={this.onHide}
                      emailTemplatesList={this.props.emailTemplatesList}
                      emailTemplate={this.state.emailTemplate}
                      updateEmailTemplateSelection={this.updateEmailTemplateSelection}
                      candidate={fullUser}
                      application={fullApplication}
                      showTemplates={true}
                      footer={this.state.sendNotification ? this.getSkipEmailButton() : null}
                      templateEmailModal={this.state.templateEmailModal}
                    />
                  )}
                  {this.state.uploadProspectDocumentModalOpen && (
                    <UploadProspectDocumentModal
                      show={this.state.uploadProspectDocumentModalOpen}
                      onHide={() => this.setState({ uploadProspectDocumentModalOpen: false })}
                      submitAdditionalDocument={this.submitUserAttachment}
                      postSubmitCallback={() => {
                        this.fetchProspect();
                        this.fetchEvents();
                      }}
                    />
                  )}
                  <div className="QuickProfile__subheader">
                    <div
                      className="QuickProfile__subheader-item--small"
                      style={{ marginBottom: '5px' }}
                    >
                      {`Entered as prospect ${moment(fullUser.created_at).format('l')}`}
                    </div>
                    {updated && (
                      <div className="QuickProfile__subheader-item--small">
                        <span data-tip data-for="last-updated">
                          Last updated
                        </span>
                        {` ${moment(updated).format('l')}${updated_by ? ` by ${updated_by}` : ''}`}
                      </div>
                    )}
                  </div>

                  <div className="QuickProfile__applicant-details">
                    <div className="QuickProfile__applicant-details-contact contact">
                      {fullUser.email && (
                        <EmailSection>
                          <img src={emailIcon} alt="" />
                          &nbsp;&nbsp;
                          {fullUser.email}
                        </EmailSection>
                      )}
                      <PhoneSection
                        onProspectList={true}
                        partialUser={this.props.partialUser}
                        fullUser={fullUser}
                        fetchProspect={this.fetchProspect}
                      />
                    </div>
                    {this.renderConvertToCandidateButton()}
                  </div>

                  <StatusAndSourceContainer onProspectList={true}>
                    <ProspectStatusSwitcher
                      candidate={this.props.partialUser}
                      updateProspectStatus={this.updateProspectStatus}
                    />

                    {this.isDistrictAdmin && (
                      <SourceSection
                        fullUser={fullUser}
                        onProspectList={true}
                        updateFullUser={this.updateFullUser}
                        sources={this.props.sources}
                      />
                    )}
                  </StatusAndSourceContainer>
                </div>

                <div className="application-specific-information">
                  {/*
                      Spec: Hide section if district hasn't created any candidate tags.

                      Spec: If there are no tags present in the district that the current
                      user can view, the section should be hidden.
                    */}
                  {this.state.districtTags.length > 0 &&
                    // check if current user_type can view any tags in district
                    this.state.districtTags.some(tag =>
                      validateTagUserType(auth.getUser(), tag)
                    ) && (
                      <div className="profile-section">
                        <CustomTagSection
                          user={auth.getUser()}
                          fullUser={fullUser}
                          assignTag={this.addTag}
                          removeTag={this.deleteTag}
                          districtTags={this.state.districtTags}
                        />
                      </div>
                    )}
                  {this.attachmentsSection()}
                  <div className="profile-section">
                    <h4 className="section-header mb1 mr1 inline-block">Activity Feed</h4>
                    <ProfileTimeline events={this.state.events} user={fullUser} />
                  </div>
                  {this.internalCandidateNotes('mt2')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const CloseButton = styled.span`
  position: absolute;
  top: 22px;
  right: 28px;
  font-size: 28px
  font-weight: bold;
  cursor: pointer;
  color: #A3A1B3 !important;

  @media screen and (max-width: 768px) {
    top: 10px;
    right: 8px;
    font-size: 24px;
  }
`;

const StatusAndSourceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1rem;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;

    .QuickProfile__applicant-details-candidate-source {
      justify-self: end;
    }
  }
`;

const ConvertButton = styled(Button)`
  height: 40px;
  width: 170px;
  margin-top: 3px;

  font-weight: bold;

  @media screen and (min-width: 1200px) {
    justify-self: end;
  }
`;

const EmailSection = styled.div`
  margin-bottom: 10px;
`;
