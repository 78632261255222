import { useEffect, useState } from 'react';
import { InformationContainer, JobViewSectionTitle } from './styles';
import { JobViewV2InformationProps } from '../types';
import { JobViewV2InternalNotes } from './JobViewV2InternalNotes';
import { getHTML, shouldShowActivityFeed } from '../utils';

import { Box } from '@mui/material';
import { sortQuestionsAndAttachments } from 'utils/roleutils';
import { JobViewV2Attachments } from './JobViewV2Attachments';
import { Accordion } from 'sharedComponents/Accordion/Accordion';

import jobActivityFeed from 'api/jobActivityFeed';
import auth from 'utils/auth';
import { ActivityFeed } from 'features/JobActivityFeed/ActivityFeed';
import { RequiredApplicationAttachment } from 'components/JobView/types';
import { useRouteMatch } from 'react-router-dom';
import { nimbleTheme } from 'theme';

export const JobViewV2Information: React.FC<JobViewV2InformationProps> = ({
  isInternalCandidate,
  job,
  shouldShow,
}) => {
  // Activity Feed State
  const [allActivity, setAllActivity] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [hasActivity, setHasActivity] = useState(false);
  const [activityError, setActivityError] = useState(false);

  const routeMatch = useRouteMatch();
  const isDistrictRouteMatch = routeMatch?.path.includes('/district/');
  const isSchoolRouteMatch = routeMatch?.path.includes('/school/');
  const isAtSchoolOrDistrictRoute = isDistrictRouteMatch || isSchoolRouteMatch;

  const questionSetAttachments: RequiredApplicationAttachment[] = job?.question_sets
    ? job.question_sets.map(questionSet => questionSet.requiredapplicationattachment_set).flat()
    : [];

  const localQuestionSetAttachments: RequiredApplicationAttachment[] = job?.role_question_sets
    ? job.role_question_sets
        .map(questionSet => questionSet.requiredapplicationattachment_set)
        .flat()
    : [];

  const jobAttachments = job.requiredapplicationattachment_set
    ? job.requiredapplicationattachment_set
    : [];

  const allAttachments = [
    ...jobAttachments,
    ...questionSetAttachments,
    ...localQuestionSetAttachments,
  ];
  const sortedAttachments = allAttachments ? sortQuestionsAndAttachments(allAttachments) : [];

  useEffect(() => {
    if (auth.isDistrictAdmin()) {
      jobActivityFeed
        .getAllActivity(job.id)
        .then(response => {
          setAllActivity(response);

          if (response.length > 0) {
            [setHasActivity(true)];
          }

          setActivityLoading(false);
        })
        .catch(() => {
          setActivityError(true);
        });
    }
  }, [job.id, job.active_status]);

  return (
    <InformationContainer data-testid="jobview-v2-information">
      {shouldShow.description && (
        <Box component="section">
          <Accordion
            sx={{ minHeight: 0, padding: 0 }}
            defaultExpanded
            title={<JobViewSectionTitle variant="h2">Description</JobViewSectionTitle>}
            dataTestId="description-container"
          >
            <Box
              data-testid="description"
              className="ql-editor"
              dangerouslySetInnerHTML={getHTML(job, 'description')}
              sx={{ padding: 0 }}
            />
          </Accordion>
        </Box>
      )}
      {shouldShow.benefits && (
        <Box component="section">
          <Accordion
            sx={{ minHeight: 0, padding: 0 }}
            defaultExpanded
            title={<JobViewSectionTitle variant="h2">Benefits</JobViewSectionTitle>}
            dataTestId="benefits-container"
          >
            <Box
              data-testid="benefits"
              className="ql-editor"
              dangerouslySetInnerHTML={getHTML(job, 'benefits')}
              sx={{ padding: 0 }}
            />
          </Accordion>
        </Box>
      )}
      {shouldShow.attachments && (
        <Box component="section">
          <JobViewV2Attachments
            attachments={sortedAttachments}
            isInternalCandidate={isInternalCandidate}
          />
        </Box>
      )}
      {shouldShow.internalNotes && (
        <JobViewV2InternalNotes internalNotes={job.internal_role_notes} />
      )}
      {shouldShowActivityFeed(job.status, isAtSchoolOrDistrictRoute) && (
        <Box sx={{ paddingX: nimbleTheme.spacing(2) }}>
          <ActivityFeed
            hasActivity={hasActivity}
            hasErrorFetchingActivity={activityError}
            jobName={job.title}
            isLoading={activityLoading}
            allActivity={allActivity}
          />
        </Box>
      )}
    </InformationContainer>
  );
};
