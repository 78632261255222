import { styled, tooltipClasses, TooltipProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';

export interface Tooltip {
  children: ReactElement<any, any> & ReactNode;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  title: string | ReactNode;
  placement?: TooltipProps['placement'];
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClose?: () => void;
  open?: boolean;
  PopperProps?: any;
}

export const Tooltip: React.FC<Tooltip> = ({
  children,
  disableFocusListener = false,
  disableHoverListener = false,
  disableTouchListener = false,
  onClick,
  onClose,
  open,
  placement,
  PopperProps,
  title,
}) => {
  return (
    <BootstrapTooltip
      title={title}
      arrow
      placement={placement}
      onClick={onClick}
      PopperProps={PopperProps}
      onClose={onClose}
      open={open}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
    >
      {children}
    </BootstrapTooltip>
  );
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '11px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
    marginTop: '4px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
    marginBottom: '4px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
    marginLeft: '4px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
    marginRight: '4px',
  },
}));
