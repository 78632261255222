import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import theme from 'ui-kit/theme';
import { useOnClickOutside } from 'hooks';
import { getDisplayNameText } from '../JobAlerts/utils';
import { DropdownFeatures } from 'utils/constants';

import {
  Container,
  InputFilterContainer,
  InputFilter,
  ClearInput,
  PositionedSearchIcon,
  DownCaret,
  Dropdown,
  SelectionList,
  SelectAllContainer,
  Option,
} from '../StateJobBoardSharedComponents';

export function StateJobBoardDropDown({
  values,
  setValues,
  options,
  displayName,
  isActive,
  setIsActive,
  mainColor = theme.uiColors.black,
  highlightColor = theme.colors.primary.light,
  showSearchInput = false,
  showSelectAllButtons = false,
  displayNameIsDynamic = false,
  optionNamePlural = '',
  error = undefined,
  dataTestId,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [localValues, setLocalValues] = useState([]);
  const [localActive, setLocalActive] = useState(false);
  const [displayNameText, setDisplayNameText] = useState(displayName);

  useEffect(() => {
    setLocalValues(values);
    setLocalActive(isActive);
  }, [values, isActive]);

  const closeNodeOnClickOutside = useOnClickOutside(() => setShowOptions(false), showOptions);

  // This useEffect allows for an optional dynamic display name even when the prop 'values' is not yet changed.
  useEffect(() => {
    if (displayNameIsDynamic) {
      const displayNameTextCopy = getDisplayNameText(localValues, optionNamePlural, options, false);
      setDisplayNameText(displayNameTextCopy);
    }
  }, [options, localValues, displayNameIsDynamic, optionNamePlural]);

  let filteredOptions = [...options];
  if (searchValue) {
    filteredOptions = filteredOptions.filter(
      option => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  };

  const selectAllValues = () => {
    setValues(options.map(o => o.value));
    setIsActive(false);
  };

  const clearAllValues = () => {
    setValues([]);
    setIsActive(false);
  };

  const toggleValue = (e, value) => {
    let updatedValues = [...localValues];
    if (!e.target.checked) {
      updatedValues = updatedValues.filter(item => item !== value);
    } else {
      updatedValues.push(value);
      // ensure no duplicates
      updatedValues = [...new Set(updatedValues)];
    }

    updateValues(updatedValues);
  };

  const updateValues = values => {
    const allSelected = values.length === options.length;
    const noneSelected = values.length === 0;
    const isActive = !allSelected && !noneSelected;

    setValues(values);
    setIsActive(isActive);
  };

  // If there is an error reset the state of the dropdown (none selected)
  useEffect(() => {
    if ([414, 400].includes(error?.response?.status)) {
      setLocalValues([]);
      setLocalActive(false);
      setValues([]);
      setIsActive(false);
    }
  }, [error, values, setLocalValues, setLocalActive, setValues, setIsActive]);

  return (
    <Container highlightColor={highlightColor} isActive={isActive} ref={closeNodeOnClickOutside}>
      <Dropdown onClick={toggleOptions} data-testid={dataTestId}>
        <p>{displayNameText}</p>
        <DownCaret fillColor={mainColor} />
      </Dropdown>
      <SelectionList showOptions={showOptions} data-testid={`${dataTestId}-${DropdownFeatures.SELECTION_LIST}`}>
        {showSelectAllButtons && (
          <SelectAllContainer>
            <span onClick={selectAllValues} data-testid={`${dataTestId}-${DropdownFeatures.SELECT_ALL}`}>Select All</span>
            <span onClick={clearAllValues} data-testid={`${dataTestId}-${DropdownFeatures.CLEAR_ALL}`}>Clear All</span>
          </SelectAllContainer>
        )}

        {showSearchInput && (
          <InputFilterContainer>
            <InputFilter
              type="text"
              placeholder=""
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              data-testid={`${dataTestId}-${DropdownFeatures.INPUT_FILTER}`}
            />
            {searchValue ? (
              <ClearInput onClick={() => setSearchValue('')} ata-testid={`${dataTestId}-${DropdownFeatures.CLEAR_INPUT}`}>×</ClearInput>
            ) : (
              <PositionedSearchIcon />
            )}
          </InputFilterContainer>
        )}

        {filteredOptions.map((option, index) => (
          <Option key={option.value} data-testid={`${dataTestId}-${DropdownFeatures.OPTION}-${option.value}`}>
            <label className="container" data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${index}`}>
              <input
                type="checkbox"
                checked={values.includes(option.value)}
                onChange={e => toggleValue(e, option.value)}
                data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${index}`}
              />
              <span className="checkmark"></span>
              {option.label}
            </label>
          </Option>
        ))}
      </SelectionList>
    </Container>
  );
}

StateJobBoardDropDown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  setValues: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  displayName: PropTypes.string.isRequired,
  displayNameIsDynamic: PropTypes.bool,
  optionNamePlural: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
  highlightColor: PropTypes.string,
  showSearchInput: PropTypes.bool,
};
