import { Vacancy } from 'types/marketplaceV2Types';
import { BaseNimbleAPI } from '../../../api/baseAPI';

class JobPostingAPI extends BaseNimbleAPI {
  get config(): { baseURL: string } {
    return { baseURL: '/api/' };
  }

  async fetchJobPostings(nces_school_id: string): Promise<[Vacancy]> {
    try {
      const url = `school/${nces_school_id}/job_postings/`;
      const response = await this.get(url);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default new JobPostingAPI();
