import { Box, Button, Typography } from '@mui/material';
import { nimbleTheme } from 'theme';
import { styled } from '@mui/material/styles';

export const CancelButton = styled(Button)({
  border: `1px solid ${nimbleTheme.palette.text.secondary}`,
  borderRadius: nimbleTheme.shape.borderRadius * 2,
  color: `1px solid ${nimbleTheme.palette.text.secondary}`,
  marginRight: nimbleTheme.spacing(2),
});

export const ChildrenContainer = styled(Box)({
  marginTop: '24px',
  width: '100%',
});

export const CTAContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export const DeleteButton = styled(Button)({
  borderRadius: nimbleTheme.shape.borderRadius * 2,
  marginLeft: nimbleTheme.spacing(2),
});

export const DeletionModalTitle = styled(Typography)({
  color: nimbleTheme.palette.text.primary,
  display: 'flex',
  fontWeight: nimbleTheme.typography.fontWeightMedium,
  justifyContent: 'center',
  paddingBottom: nimbleTheme.spacing(2),
});

export const ModalContainer = styled(Box)(props => ({
  [props.theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [props.theme.breakpoints.up('md')]: {
    width: '552px',
  },
  backgroundColor: 'white',
  borderRadius: '2px',
  left: '50%',
  maxWidth: '552px',
  padding: '24px',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
}));

export const TextContainer = styled(Typography)({
  display: 'flex',
  fontSize: nimbleTheme.typography.htmlFontSize,
  justifyContent: 'center',
  paddingBottom: nimbleTheme.spacing(4),
  textAlign: 'center',
});

export const Title = styled('h1')({
  color: '#1A1A1A',
  fontSize: '24px',
  fontWeight: 400,
  padding: 0,
});

export const Top = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});
