import { useState, useEffect } from 'react';

import theme from 'ui-kit/theme';
import { useOnClickOutside } from 'hooks';
import {
  DistrictJobBoardDropdownOptionsPropsInterface,
  DistrictJobBoardDropDownProps,
  StringNumber,
} from '../types';

import {
  Container,
  InputFilterContainer,
  InputFilter,
  ClearInput,
  PositionedSearchIcon,
  DownCaret,
  Dropdown,
  SelectionList,
  SelectAllContainer,
  Option,
  getDisplayNameText,
} from './DistrictJobBoardSharedComponents';
import { DropdownFeatures } from 'utils/constants';

export const DistrictJobBoardDropDown: React.FC<DistrictJobBoardDropDownProps> = ({
  values,
  setValues,
  options,
  displayName,
  isActive,
  setIsActive,
  updateMultiSelect,
  mainColor = 'rgb(192, 192, 192)',
  highlightColor = theme.colors.primary.light,
  showSearchInput = false,
  showSelectAllButtons = false,
  displayNameIsDynamic = false,
  optionNamePlural = '',
  error,
  dataTestId,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [localValues, setLocalValues] = useState([]);
  const [displayNameText, setDisplayNameText] = useState<StringNumber>(displayName);

  useEffect(() => {
    setLocalValues(values.map(value => value));
  }, [values, isActive]);

  const closeNodeOnClickOutside = useOnClickOutside(() => setShowOptions(false), showOptions);

  useEffect(() => {
    if (displayNameIsDynamic) {
      const displayNameTextCopy = getDisplayNameText(localValues, optionNamePlural, options, false);
      setDisplayNameText(displayNameTextCopy);
    }
  }, [options, localValues, displayNameIsDynamic, optionNamePlural]);

  let filteredOptions: DistrictJobBoardDropdownOptionsPropsInterface[] = [...options];
  if (searchValue) {
    filteredOptions = filteredOptions.filter(
      option => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  const setFieldName = () => {
    let fieldName;
    if (displayNameText === 'Employment Type') {
      fieldName = 'fulltime';
    } else {
      fieldName = 'schools';
    }
    return fieldName;
  };

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  };

  const selectAllValues = () => {
    setValues(options);
    updateMultiSelect(options, setFieldName());
    setIsActive(false);
  };

  const clearAllValues = () => {
    setValues([]);
    updateMultiSelect([], setFieldName());
    setIsActive(false);
  };

  const toggleValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DistrictJobBoardDropdownOptionsPropsInterface
  ) => {
    let updatedValues = [...localValues];
    if (!e.target.checked) {
      updatedValues = updatedValues.filter(item => item !== value);
    } else {
      updatedValues.push(value);
      updatedValues = [...new Set(updatedValues)];
    }
    updateValues(updatedValues);
  };

  const updateValues = (values: StringNumber[]) => {
    const allSelected = values.length === options.length;
    const noneSelected = values.length === 0;
    const isActive = !allSelected && !noneSelected;

    setValues(values);
    updateMultiSelect(values, setFieldName());
    setIsActive(isActive);
  };

  useEffect(() => {
    if ([414, 400].includes(error?.response?.status)) {
      setLocalValues([]);
      setValues([]);
      setIsActive(false);
    }
  }, [error, values, setLocalValues, setValues, setIsActive]);

  return (
    <Container highlightColor={highlightColor} isActive={isActive} ref={closeNodeOnClickOutside}>
      <Dropdown onClick={toggleOptions} data-testid={dataTestId}>
        <p>{displayNameText}</p>
        <DownCaret fillColor={mainColor} />
      </Dropdown>
      <SelectionList showOptions={showOptions}>
        {showSelectAllButtons && (
          <SelectAllContainer>
            <span onClick={selectAllValues}  data-testid={`${dataTestId}-${DropdownFeatures.SELECT_ALL}`}>Select All</span>
            <span onClick={clearAllValues}  data-testid={`${dataTestId}-${DropdownFeatures.CLEAR_ALL}`}>Clear All</span>
          </SelectAllContainer>
        )}

        {showSearchInput && (
          <InputFilterContainer>
            <InputFilter
              type="text"
              placeholder=""
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              data-testid={`${dataTestId}-${DropdownFeatures.INPUT_FILTER}`}
            />
            {searchValue ? (
              <ClearInput onClick={() => setSearchValue('')} data-testid={`${dataTestId}-${DropdownFeatures.CLEAR_INPUT}`}>×</ClearInput>
            ) : (
              <PositionedSearchIcon />
            )}
          </InputFilterContainer>
        )}

        {filteredOptions.map((option, index) => (
          <Option key={option.value} data-testid={`${dataTestId}-${DropdownFeatures.OPTION}-${index}`}>
            <label className="container" data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${index}`}>
              <input
                type="checkbox"
                checked={values.includes(option)}
                onChange={values => toggleValue(values, option)}
                data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${index}`}
              />
              <span className="checkmark"></span>
              {option.label}
            </label>
          </Option>
        ))}
      </SelectionList>
    </Container>
  );
};
