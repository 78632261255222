import {
  Autocomplete,
  Checkbox,
  FormControl,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { ATSJobClosureNotificationTestIds } from 'data-testids/ATS';
import { BasicSelect } from 'sharedComponents/Select';
import { BlankModal } from 'sharedComponents/Modal';
import {
  Bottom,
  CTAContainer,
  DescriptionText,
  ElementAndErrorText,
  FormElements,
  Middle,
  ModalBody,
  modalStyles,
  ModalTitle,
  NumberInput,
  StyledErrorText,
} from './styles';
import { JobClosureModalProps } from './types';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const JobClosureModal: React.FC<JobClosureModalProps> = ({
  applicationStatuses,
  applicationStatusId,
  districtAndSuperAdmins,
  handleAdd,
  handleCloseModal,
  handleEdit,
  handleStatusChange,
  handleSubscribersChange,
  handleThresholdChange,
  hasExistingNotifications,
  isLoadingDataOrMutationPending,
  isModalActive,
  isSubmitting,
  subscriberIds,
  thresholdValue,
}) => {
  const theme = useTheme();

  const previousSubscribers = districtAndSuperAdmins.reduce((acc, admin) => {
    subscriberIds?.map(subscriberId => {
      if (admin.id === subscriberId) {
        acc.push({
          name: admin.name,
          id: admin.id,
        });
      }
    });

    return acc;
  }, []);

  const getTransformedPossibleSubscribers = () =>
    districtAndSuperAdmins.map(admin => ({ name: admin.name, id: admin.id }));

  const hasThresholdError = isSubmitting && thresholdValue < 1;
  const hasStatusError = isSubmitting && !applicationStatusId;
  const hasSubscribersError =
    (isSubmitting && !subscriberIds) || (isSubmitting && subscriberIds.length === 0);

  const isInFormErrorState = hasThresholdError || hasStatusError || hasSubscribersError;

  return (
    <BlankModal
      dataTestId={ATSJobClosureNotificationTestIds.MODAL}
      onClose={handleCloseModal}
      open={isModalActive}
      sx={modalStyles}
      header={
        <ModalTitle data-testid={ATSJobClosureNotificationTestIds.MODAL_TITLE}>
          <Typography variant="h5" sx={{ fontWeight: theme.typography.fontWeightMediumBold }}>
            {hasExistingNotifications ? 'Edit Notification' : 'Create a Job Closure Notification'}
          </Typography>
        </ModalTitle>
      }
      body={
        <ModalBody>
          <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
            Send an email that this job is ready to be closed when:
          </Typography>
          <Middle>
            <FormControl error={hasThresholdError}>
              <FormElements>
                <DescriptionText>this number of candidates:</DescriptionText>
                <ElementAndErrorText>
                  <NumberInput
                    defaultValue={thresholdValue}
                    onChange={handleThresholdChange}
                    placeholder="Select a threshold"
                    error={hasThresholdError}
                    fullWidth
                    type="number"
                    required
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                  {hasThresholdError && (
                    <StyledErrorText
                      data-testid={ATSJobClosureNotificationTestIds.THRESHOLD_ERROR_TEXT}
                    >
                      Please select a threshold
                    </StyledErrorText>
                  )}
                </ElementAndErrorText>
              </FormElements>
            </FormControl>
            <FormControl error={hasStatusError}>
              <FormElements>
                <DescriptionText>reach the status of:</DescriptionText>
                <ElementAndErrorText>
                  <BasicSelect
                    defaultValue={applicationStatusId || ''}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    handleChange={handleStatusChange}
                    options={applicationStatuses}
                    sx={{
                      backgroundColor: 'white !important',
                      '.MuiInputBase-root': {
                        border: hasStatusError
                          ? `1px solid ${theme.palette.error.main}`
                          : 'inherit',
                      },
                    }}
                  />
                  {hasStatusError && (
                    <StyledErrorText
                      data-testid={
                        ATSJobClosureNotificationTestIds.APPLICATION_STATUS_ID_ERROR_TEXT
                      }
                    >
                      Please select a status
                    </StyledErrorText>
                  )}
                </ElementAndErrorText>
              </FormElements>
            </FormControl>
          </Middle>
          <Bottom>
            <FormControl error={hasSubscribersError}>
              <Typography sx={{ fontSize: 'inherit', marginBottom: theme.spacing(1) }}>
                Select users to notify:
              </Typography>
              <Autocomplete
                disableCloseOnSelect
                value={previousSubscribers}
                getOptionKey={option => option.id}
                getOptionLabel={option => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                multiple
                sx={{
                  '.MuiInputBase-root': {
                    border: hasSubscribersError
                      ? `1px solid ${theme.palette.error.main}`
                      : 'inherit',
                  },
                }}
                onChange={(_event, newValues) => handleSubscribersChange(newValues)}
                options={getTransformedPossibleSubscribers()}
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;
                  return (
                    <li key={option.id} {...optionProps}>
                      <Checkbox
                        checked={selected}
                        checkedIcon={checkedIcon}
                        icon={icon}
                        style={{ marginRight: theme.spacing(1) }}
                      />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={params => (
                  <TextField {...params} placeholder="Type or use dropdown to add users" />
                )}
              />
              {hasSubscribersError && (
                <StyledErrorText
                  data-testid={ATSJobClosureNotificationTestIds.SUBSCRIBERS_ERROR_TEXT}
                >
                  Please select a user
                </StyledErrorText>
              )}
            </FormControl>
          </Bottom>
          <CTAContainer>
            <SecondaryButton
              onClick={handleCloseModal}
              disabled={isLoadingDataOrMutationPending}
              size="extraLarge"
              sx={{ marginRight: theme.spacing(2) }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              dataTestId={ATSJobClosureNotificationTestIds.MODAL_PRIMARY_BUTTON}
              disabled={isLoadingDataOrMutationPending || isInFormErrorState}
              onClick={hasExistingNotifications ? handleEdit : handleAdd}
              size="extraLarge"
            >
              {hasExistingNotifications ? 'Save' : 'Create'}
            </PrimaryButton>
          </CTAContainer>
        </ModalBody>
      }
    />
  );
};
