import { notify } from 'react-notify-toast';

function stringify(message) {
  if (!message) return 'Unknown error';
  if (typeof message === 'string') return message;
  if (!message.response) return JSON.stringify(message);
  if (!message.response.data) return JSON.stringify(message.response);
  return JSON.stringify(message.response.data);
}

/**
 * Shows an error to the user that requires them to do something drastic, like refresh
 * the page or contact support.
 */
export function showTotalFailure(message) {
  let preamble =
    "We're sorry - there was an error with your application and it was not submitted " +
    'successfully. For immediate assistance, please contact support@hirenimble.com with ' +
    'the following information: ';
  notify.show(preamble + stringify(message), 'error', -1);
}

/**
 * Shows an error to the user that requires them to do something drastic, like refresh
 * the page or contact support. Similar to showTotalFailure but intended for school
 * and district admins as opposed to candidates.
 */
export function showTotalFailureAdmin(message) {
  notify.show(stringify(message), 'error', -1);
}

/**
 * Shows a warning to the user, indicating that the app may work if they try again soon, or it
 * may be working already.
 * Define custom colors using myColor.
 * Add a time limit by replacing -1 with number of milliseconds (e.g. 10000 for 10 seconds).
 * We currently want the warning to stay on top of the page indefinitely.
 */
export const myColor = { background: '#00B88D', text: '#FFFFFF' };
export function showWarning(message, time = -1, color = myColor) {
  notify.show(stringify(message), 'custom', time, color);
}

export function showErrorNotification(message, time = 10000) {
  notify.show(stringify(message), 'error', time);
}

export const myColorWarning = { background: '#FF7B5F', text: '#FFFFFF' };
export function showStrongWarning(message, time = -1) {
  notify.show(stringify(message), 'custom', time, myColorWarning);
}

export const messageColor = { background: '#333333', text: '#F2F2F2' };
export function showMessage(message, time = 5000) {
  notify.show(stringify(message), 'custom', time, messageColor);
}

/**
 * Error message when Google login account doesn't have a last or first name
 */
export function googleNameError() {
  const message = `In order to use Google sign on, we require that your Google
  account have a first and last name associated with it. Please update your
  Google account with this information and try again or create your Nimble account manually,
  by clicking "Sign up" and entering your full name and email. For questions or assistance,
  contact support@hirenimble.com.`;
  notify.show(message, 'custom', -1, myColor);
}

export const leavePageWarning = 'Are you sure you want to leave? You have unsaved changes.';
