import { Component } from 'react';

import PropTypes from 'prop-types';

import ButtonCheckBox from '../common/ButtonCheckBox';
import { ATSDistrictJobsListCategoryFilterDataTestIds } from 'data-testids/ATS';

export default class JobEditSubjects extends Component {
  static propTypes = {
    subjects: PropTypes.arrayOf(PropTypes.number).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      subjects: props.subjects,
    };
  }

  toggleAllSubcategories = label => {
    const subcats = this.getSubcategories(label);
    const subjects = this.state.subjects;
    if (this.allSubcategoriesChecked(label)) {
      subcats.forEach(s => subjects.splice(subjects.indexOf(s.id), 1));
    } else {
      subcats.forEach(s => {
        if (!subjects.includes(s.id)) {
          subjects.push(s.id);
        }
      });
    }
    this.setState({ subjects }, this.runSearch);
  };

  allSubcategoriesChecked = label => {
    const subcats = this.getSubcategories(label);
    const subjects = this.state.subjects;
    for (let i = 0; i < subcats.length; i++) {
      if (!subjects.includes(subcats[i].id)) {
        return false;
      }
    }
    return true;
  };

  getSubcategories = label => {
    const categories = this.props.categories;
    let subcats;
    if (label) {
      const category = categories.find(c => c.label === label) || {};
      subcats = category.subcategories.slice() || [];
    } else {
      subcats = [];
      categories.forEach(c => {
        c.subcategories.forEach(s => {
          subcats.push(s);
        });
      });
    }
    return subcats;
  };

  updateSubject = e => {
    // toggle one subject
    var updateSubjects = this.state.subjects;
    var subject = parseInt(e.target.value, 10);

    if (updateSubjects.includes(subject)) {
      updateSubjects.splice(updateSubjects.indexOf(subject), 1);
    } else {
      updateSubjects.push(subject);
    }
    this.setState({ subjects: updateSubjects }, this.runSearch);
  };

  runSearch = () => {
    // reformat subjects as a mapping of integers before onChange
    const subjects = this.state.subjects;
    const newSubjectsMap = subjects.map(v => {
      var obj = {};
      obj['value'] = v;
      return obj;
    });
    this.props.onChange(newSubjectsMap);
  };

  render() {
    return (
      <div className="flex">
        <div className="jobedit-grade-column">
          <div className="jobedit-grade-header">
            <ButtonCheckBox
              checked={this.allSubcategoriesChecked(null)}
              value={this.allSubcategoriesChecked(null)}
              onChange={() => this.toggleAllSubcategories(null)}
              label="All Categories"
              dataTestId={ATSDistrictJobsListCategoryFilterDataTestIds.ALL_CATEGORIES_OPTION}
            />
          </div>
        </div>

        {this.props.categories.map((category, categoryIndex) => (
          <div className="jobedit-grade-column" key={categoryIndex}>
            <div className="jobedit-grade-header jobedit-category">
              <ButtonCheckBox
                checked={this.allSubcategoriesChecked(category.label)}
                value={this.allSubcategoriesChecked(category.label)}
                onChange={() => this.toggleAllSubcategories(category.label)}
                label={category.label}
                dataTestId={`${ATSDistrictJobsListCategoryFilterDataTestIds.CATEGORY_OPTION}-${categoryIndex}`}
              />
            </div>
            {category.subcategories.map((subcategory, subcategoryIndex) => (
              <ButtonCheckBox
                key={subcategoryIndex}
                checked={this.state.subjects.includes(subcategory.id)}
                value={subcategory.id}
                onChange={this.updateSubject}
                label={subcategory.label}
                dataTestId={`${ATSDistrictJobsListCategoryFilterDataTestIds.SUBCATEGORY_OPTION}-${categoryIndex}-${subcategoryIndex}`}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }
}
