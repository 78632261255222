export enum ServerErrorModalDataTestIds {
  ModalContainer = 'server-error',
}

export const emailSupportErrorMessage =
  "Please ensure all fields are filled out and try again. If you have any questions, please email support@hirenimble.com and we'll help you out right away.";

export const CandidateErrors = {
  GENERAL: 'Looks like something went wrong. Please refresh the page and try again.',
  APPLICATION_WORK_HISTORY: 'Please complete all required fields in the highlighted entries below.',
  APPLICATION_CREDENTIALS: `Oops, there was a problem with the credential section. ${emailSupportErrorMessage}`,
  APPLICATION_LANGUAGES: `Oops, there was a problem with the language section. ${emailSupportErrorMessage}`,
  APPLICATION_ALREADY_EXISTS: `Oops, our records show that you've already applied for this role. ${emailSupportErrorMessage}`,
  APPLICATION_OPEN_RESPONSE: `Oops, there was a problem with the open response section. ${emailSupportErrorMessage}`,
  APPLICATION_SUBMISSION:
    "Oops, there was a problem with your application. Please email support@hirenimble.com and we'll help you out right away.",
  APPLICATION_RESUME:
    "Oops, there was a problem uploading your resume. If you haven't already, please try uploading as a PDF file. If you have any questions, please email support@hirenimble.com and we'll help you out right away.",
  APPLICATION_ATTACHMENT_SAVE_ERROR:
    "Looks like there was a problem saving your attachments. Please refresh the page and try again. If you're still having trouble, you can contact us at support@hirenimble.com and we'll be happy to help.",
  SSN_ALREADY_EXISTS:
    'Another account with that SSN found. Please log in to that account to proceed.',
  SSN_RETRIEVAL_FAILED:
    'Oops, there was a problem retrieving your SSN. Please contact support@hirenimble.com for assistance.',
  TASK_UPDATE_FAILURE:
    'There was a problem updating this task. Please email support@hirenimble.com for assistance.',
} as const;

export type CandidateErrorKey = keyof typeof CandidateErrors;
