import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { BasicSelectProps } from './types';

export const BasicSelect: React.FC<BasicSelectProps> = ({
  options,
  handleChange,
  sx,
  size,
  formControlProps = null,
  displayName = '',
  id = '',
  defaultValue = '',
  dataTestId,
  multiple,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultValue as string);

  const onChangeHandler = (event, value) => {
    const optionValue = value.props.value || event.target.value;

    setSelectedOption(optionValue);
    handleChange(optionValue);
  };

  return (
    <FormControl size={size} {...formControlProps} sx={sx}>
      {displayName && <InputLabel sx={sx}>{displayName}</InputLabel>}
      <Select
        id={id}
        value={selectedOption}
        data-testid={dataTestId || 'basic-select'}
        label={displayName}
        sx={sx}
        size={size}
        multiple={multiple}
        onChange={(event, value) => {
          onChangeHandler(event, value);
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.label || option.name || option.value}
            value={option.value || option.id}
          >
            {option.label || option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
