import { BlankModal } from './BlankModal';
import {
  CancelButton,
  CTAContainer,
  DeleteButton,
  DeletionModalTitle,
  TextContainer,
} from './styles';
import { DeletionModalProps } from './types';
import { SharedComponentsDeletionModalTestIds } from 'data-testids/SharedComponents';
import { useTheme } from '@mui/material';

export const DeletionModal: React.FC<DeletionModalProps> = ({
  onClose,
  onDelete,
  open,
  text,
  title,
}) => {
  const theme = useTheme();

  return (
    <BlankModal
      open={open}
      sx={{ borderRadius: theme.shape.borderRadius * 2, padding: theme.spacing(6) }}
      onClose={onClose}
      header={
        <DeletionModalTitle variant="h5" data-testid={SharedComponentsDeletionModalTestIds.TITLE}>
          {title}
        </DeletionModalTitle>
      }
      body={
        <>
          <TextContainer variant="body1" data-testid={SharedComponentsDeletionModalTestIds.TEXT}>
            {text}
          </TextContainer>
          <CTAContainer>
            <CancelButton
              color="inherit"
              data-testid={SharedComponentsDeletionModalTestIds.CANCEL_BUTTON}
              onClick={onClose}
              size="extraLarge"
              variant="outlined"
            >
              Cancel
            </CancelButton>
            <DeleteButton
              color="error"
              data-testid={SharedComponentsDeletionModalTestIds.DELETE_BUTTON}
              onClick={onDelete}
              size="extraLarge"
              variant="contained"
            >
              Delete
            </DeleteButton>
          </CTAContainer>
        </>
      }
    />
  );
};
