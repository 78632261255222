import { useHistory } from 'react-router-dom';

import { getJobStatusColor, roleStatusByValue } from 'utils/enums';
import { JobViewHeaderProps } from '../types';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { CTAContainer, SectionContainer, TextAndStatus } from '../styles';
import { ShareButton } from 'sharedComponents/Buttons/ShareButton';
import auth from 'utils/auth';
import { ATSJobViewDataTestIds } from '../../../data-testids/ATS';

export const JobViewHeader: React.FC<JobViewHeaderProps> = ({
  isDistrictAdmin,
  isInternalCandidate,
  isPreview,
  isSchoolUser,
  job,
  onSubmit,
  openShareModal,
  userDistrict,
}) => {
  const history = useHistory();
  const showSocialMediaShareButton =
    job.status === roleStatusByValue.active &&
    job.active_status === 1 &&
    !auth.inPostingOnlyDistrict();
  const jobDistrict = job.district?.id;
  const shouldShowEditButton = jobDistrict === userDistrict;
  const isPostingOnly = job.district.posting_only;
  const isDistrictAdminOrJobIsPending =
    isDistrictAdmin || job.status === roleStatusByValue.submitted;

  const backToJobEdit = () => history.push(`/district/jobedit/${job.id}`);

  const getTitleButton = () => {
    if (isPreview) {
      const postButtonText = auth.isDistrictUserOnly() ? 'Submit to Admin' : 'Post Job';

      return (
        <CTAContainer>
          <SecondaryButton
            sx={{ width: '140px', height: '42px' }}
            onClick={backToJobEdit}
            dataTestId="back-to-edit-button"
          >
            Back to Edit
          </SecondaryButton>
          <PrimaryButton
            sx={{ width: '140px', height: '42px', marginLeft: '20px' }}
            onClick={onSubmit}
            dataTestId="post-button"
          >
            {postButtonText}
          </PrimaryButton>
        </CTAContainer>
      );
    }

    if (isDistrictAdminOrJobIsPending) {
      return (
        <>
          {shouldShowEditButton && (
            <CTAContainer>
              {showSocialMediaShareButton && <ShareButton onClick={() => openShareModal()} />}
              <PrimaryButton
                size="extraLarge"
                onClick={backToJobEdit}
                sx={{ width: '140px', height: '42px', marginLeft: '20px' }}
                dataTestId="edit-button"
              >
                Edit
              </PrimaryButton>
            </CTAContainer>
          )}
        </>
      );
    } else if (isSchoolUser || auth.isDistrictUserOnly()) {
      return <>{showSocialMediaShareButton && <ShareButton onClick={() => openShareModal()} />}</>;
    } else {
      if (isPostingOnly) {
        const encodedJobTitleAndEmailSubject = encodeURIComponent('Application for: ' + job.title);
        let linkToApply: string = job.link_to_ats;

        linkToApply = linkToApply.includes('http')
          ? linkToApply
          : `mailto:${linkToApply}?subject=${encodedJobTitleAndEmailSubject}`;

        return (
          <a href={linkToApply.length > 0 ? linkToApply : ''}>
            <PrimaryButton sx={{ width: '140px', height: '42px', marginLeft: '20px' }}>
              APPLY
            </PrimaryButton>
          </a>
        );
      } else {
        const urlQuery = isInternalCandidate ? `?internal=${window.btoa('true')}` : '';
        const roleId =
          window.location.search.indexOf('is_school_role=1') > -1 ? job.district_role : job.id;

        return (
          <PrimaryButton
            size="extraLarge"
            dataTestId={ATSJobViewDataTestIds.APPLY_BUTTON}
            onClick={() => history.push(`/applicant-profile/${roleId}/${urlQuery}`)}
            sx={{ width: '140px', height: '42px', marginLeft: '20px' }}
          >
            APPLY
          </PrimaryButton>
        );
      }
    }
  };

  return (
    <SectionContainer>
      <TextAndStatus>
        <span
          data-testid="status-circle"
          className={`status-circle ${getJobStatusColor(job.status)} inline-block`}
        />
        <h3 data-testid={ATSJobViewDataTestIds.TITLE_TEXT}>{job.title}</h3>
      </TextAndStatus>
      {getTitleButton()}
    </SectionContainer>
  );
};
