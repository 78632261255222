export const GRADE_MAPPING = {
  early_childhood: [0],
  elementary: [13, 1, 2, 3, 4, 5],
  middle_school: [6, 7, 8],
  high_school: [9, 10, 11, 12],
};

export const EMPLOYMENT_TYPE = {
  partTime: 0.5,
  fullAndPartTime: 0.75,
  fullTime: 1,
};
